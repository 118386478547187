import {reduxState, stateData}  from 'values';

const initialState = {
    soccer:     [],
    basket:     [],
    volley:     [],
    top:        [],
    status:     stateData.state1,
};

const reducer = (state = initialState, action) =>{
    if(action.type === reduxState.get_products){
        return{
            ...state,
            soccer:     action.soccer,
            basket:     action.basket,
            volley:     action.volley,
            top:        action.top,
            status:     stateData.state2
        }
    }
    return state
}
export default reducer;