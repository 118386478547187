import {reduxState}  from 'values';

export const getAlliances = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_alliances,
            data,
        })
    }
}
