import {useState}   from 'react';
import {toast}      from 'react-toastify';

// Api
import {validateData, postData} from 'utils/subscriber';
// Styles
import {useStyles}  from '../../style';

const Subscribe = () => {
    const classes = useStyles()
    const [email, setEmail]     = useState('')
    const [loading, setLoading] = useState(false)

    const cleanData = () =>{
        setLoading(false)
        setEmail('')
    }

    const addSubscriber = async() =>{
        setLoading(true)
        const res1 = validateData(email)
        if(res1.flag){
            const res2 = await postData(email)
            if(res2){
                toast.success('Suscripción exitosa')
            } else{
                toast.warn('Por favor verifica que tus datos esten correctos.')
            }
            setTimeout(()=> 
                cleanData()
            , 1000)
        } else{
            setLoading(false)
            toast.warn(res1.message)
        }
    }
    const standBy = ()=>{}
    return (
        <div style={{display:'flex'}}>
            <input value={email} onChange={e=>setEmail(e.target.value)} style={{fontFamily:'av_book'}}
                className={classes.input_subscribe} placeholder='Email' />

            <button className={classes.button_subscribe} style={{fontFamily:'m_bold'}}
                onClick={loading?standBy:addSubscriber}>
                    {loading?'Cargando...':'SUSCRIBIRME'}
            </button>
        </div>
    );
};

export default Subscribe;