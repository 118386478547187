import { useState, Fragment } from "react";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";

// Values
import { Ic_menu, paths, Logo_small, Ic_cart } from "values";
// Styles
import { useStyles } from "style";

const Title = ({ path, newPath, text, change }) => {
  return (
    <div style={{ width: 200, backgroundColor: path === newPath ? "#f36c22" : "#fff" }}>
      <p
        onClick={() => change(newPath)}
        style={{
          marginLeft: 20,
          cursor: "pointer",
          color: path === newPath ? "#fff" : "#000",
          fontSize: 20,
          userSelect: "none",
        }}
      >
        {text}
      </p>
    </div>
  );
};

const MenuDrawer = ({ store, menuChange, path, login }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const change = (path) => {
    menuChange(path);
    setOpen(false);
  };
  const openLoginModal = () => {
    login();
    setOpen(false);
  };
  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 15px" }}>
        <img alt="" src={Ic_menu} style={{ cursor: "pointer", width: 35 }} onClick={() => setOpen(true)} />
        <img onClick={() => change("")} alt="" src={Logo_small} style={{ width: 100 }} />

        {/* <div style={{ display: "flex", position: "relative", left: -20 }}>
          <div className={classes.button_card} onClick={() => change(paths.cartPath)}>
            <img alt="" loading="lazy" draggable="false" src={Ic_cart} style={{ width: 25 }} />
          </div>
          {store.length !== 0 && (
            <div
              style={{
                background: "#2ed37c",
                position: "absolute",
                height: 28,
                width: 28,
                color: "#fff",
                fontSize: 14,
                fontWeight: 600,
                bottom: -10,
                right: 35,
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label>{store.length}</label>
            </div>
          )}
        </div> */}
      </div>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Title path={path} newPath={paths.homePath} text={paths.homeTitle} change={change} />
        <Title path={path} newPath={paths.usPath} text={paths.usTitle} change={change} />
        <Title path={path} newPath={paths.soccerPath} text={paths.soccerTitle} change={change} />
        <Title path={path} newPath={paths.basketPath} text={paths.basketTitle} change={change} />
        <Title path={path} newPath={paths.voleyPath} text={paths.voleyTitle} change={change} />
        <Title path={path} newPath={paths.contactPath} text={paths.contactTitle} change={change} />
        <Title path={path} newPath={paths.alliancesPath} text={paths.alliancesTitle} change={change} />
        {/* <Title path={path} newPath={paths.cartPath} text={paths.cartTitle} change={change} /> */}
        {/* <div style={{ width: 200 }}>
          <p
            onClick={() => openLoginModal()}
            style={{ marginLeft: 20, cursor: "pointer", fontSize: 20, userSelect: "none" }}
          >
            {"Login"}
          </p>
        </div> */}
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
});

export default connect(mapStateToProps, {})(MenuDrawer);
