import { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
// Values
import { useStyles } from "style";
import { Ic_marker_map } from "values";

const Location = () => {
  const classes = useStyles();
  const [key] = useState("AIzaSyC0jBGUXwH72rnF5gHFR03Zev0u2fKDp-E");
  const [center] = useState({ lat: 20.6350913, lng: -103.3373363 });
  return (
    <div>
      <Container>
        <p style={{ fontSize: 60, marginBottom: 20, fontWeight: 600, fontFamily: "m_bold" }}>{"Visítanos"}</p>
      </Container>
      <div style={{ width: "100%", height: 400, position: "relative" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: key }}
          options={{ fullscreenControl: false, zoomControl: false }}
          defaultCenter={center}
          defaultZoom={16}
        >
          <img alt="" lat={center.lat} lng={center.lng} style={{ width: 30, height: 30 }} src={Ic_marker_map} />
        </GoogleMapReact>
      </div>
      <Container style={{ marginBottom: 150, marginTop: 50 }}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <div className={classes.container_contact_info}>
              <div>
                <p className={classes.title_info_contact} style={{ fontFamily: "m_semi_bold" }}>
                  {"Planta"}
                </p>
                <br />
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Carr. Sayula- San Gabriel Km 40.5"}</p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Col. Cruz Verde CP 49700"}</p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"San Gabriel Jalisco"}</p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Tel: 343 427 0322"}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.container_contact_info}>
              <div>
                <p className={classes.title_info_contact} style={{ fontFamily: "m_semi_bold" }}>
                  {"Oficina Comercial"}
                </p>
                <br />
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Av. Caña #3638"}</p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Col. La Nogalera CP 44470"}</p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Guadalajara, Jalisco"}</p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Tel: 33 2005 0564"}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.container_contact_info}>
              <div>
                <p className={classes.title_info_contact2} style={{ fontFamily: "m_semi_bold" }}>
                  {"Línea Directa Ventas:"}
                </p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"33 2005 0564"}</p>
                <br />
                <p className={classes.title_info_contact3} style={{ fontFamily: "m_semi_bold" }}>
                  {"Horarios de atención"}
                </p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"Lunes a Viernes"}</p>
                <p style={{ fontSize: 20, margin: 0, fontFamily: "av_book" }}>{"09:00 am - 06:00 pm"}</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Location;
