import { urlApi, urlPath } from '../api';

const url = urlApi + urlPath.paymentWithCard

export const paymentWithCard = async (name, lastName, phone, address, numExt, numInt, state, municipality, area, postalCode, email,
    legalName, invoiceEmail, rfc, invoiceAddress, invoiceInt, invoiceExt, invoiceArea, invoiceMunicipality, invoiceState, invoicePostalCode, cfdi,
    userId, tokenCard, coupon, products, paymentMethod, orderId, flagEmail, salesId, token) => {
    const obj = {
        name, lastName, phone, address, numExt, numInt, state, municipality, area, postalCode, email,
        legalName, invoiceEmail, rfc, invoiceAddress, invoiceInt, invoiceExt, invoiceArea, invoiceMunicipality, invoiceState, invoicePostalCode, cfdi,
        userId, token: tokenCard, coupon, products, paymentMethod, orderId, flagEmail, salesId
    }
    let status1 = 200
    let status2 = 200
    let id = ''

    const request = {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // console.log('Obj: ', obj);

    await fetch(url, request)
        .then(async (res) => {
            if (res.status === 200) {
                status1 = 200
            } else {
                status1 = 400
            }
            return res.json()

        })
        .then((res) => {
            if (status1 === 200) {
                status2 = res.error
                id = res.id ? res.id : ''
            }
            if (status1 === 400) {
                status2 = res.error
            }
        })

    return { status1, status2, id }
}

export const createACustomer = async (name, lastName, email, phone, postalCode, address, state, municipality, coupon, products) => {
    const urlUax = urlApi + urlPath.createCustomer

    let flag = false
    let id = ''
    const obj = {
        name, lastName, email, phone, postalCode,
        address, state, municipality, coupon, products
    }

    const request = {
        method: "POST",
        body: JSON.stringify(obj),
        headers: { 'Content-Type': 'application/json' }
    }

    await fetch(urlUax, request)
        .then(async (res) => {
            if (res.status === 200) {
                console.log(res.status);
                flag = true
                return res.json()
            }
        })
        .then((response) => {
            if (flag === true) {
                // console.log('Id: ', response.id);
                id = response.id
            }
        })
    return id
}

export const validateCards = (card) => {
    const typeCard = window.Conekta.card.getBrand(card.numberCard)
    const ccv = window.Conekta.card.validateCVC(card.ccv)
    const date = window.Conekta.card.validateExpirationDate(card.month, card.year)

    if (!card.nameCard) {
        return { flag: false, message: 'Parece que algo anda mal con el nombre del titular' }
    }
    if (!typeCard) {
        return { flag: false, message: 'Parece que algo anda mal con la tarjeta' }
    }
    if (!date) {
        return { flag: false, message: 'Parece que algo anda mal con las fechas de la tarjeta' }
    }
    if (!ccv) {
        return { flag: false, message: 'Parece que algo anda mal con el ccv' }
    }
    return { flag: true, message: '' }
}

