import Banner   from './Banner';
import Cards    from './Cards';
import Hashtag  from '../global/Hashtag';
import Footer   from '../global/Footer'


const Alliances = () => {
    return (
        <div>
            <Banner />
            <Cards />
            <Hashtag />
            <Footer />
        </div>
    );
};

export default Alliances;