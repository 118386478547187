
// Components
import Banner from './Banner';
import Hashtag from '../global/Hashtag';
import Values from './Values';
import Footer from '../global/Footer'

// Values
import { Banner_img } from '../../values';

const Us = () => {
    return (
        <div>
            <Banner />
            <Values />
            <img alt='' loading='lazy' src={Banner_img} style={{ width: '100%', marginBottom: -4 }} />
            <Hashtag />
            <Footer />
        </div>
    );
};

export default Us;