import { Container, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// Components
import Subscribe from "./Subscribe";
// Values
import { Logo_small, Ic_twitter, Ic_youtube, Ic_facebook, Ic_instagram, paths } from "values";
// Style
import { useStyles } from "style";
const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div style={{ backgroundColor: "#163F81", paddingTop: 50, position: "relative" }}>
      <label style={{ position: "absolute", bottom: 5, left: 5, fontSize: 10, color: "#fff" }}>V 1.4.2</label>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <div className={classes.container_footer}>
              <div>
                <img alt="gaser" loading="lazy" src={Logo_small} />
                <div style={{ height: 60 }} />
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"Lunes a Viernes"}</p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"09:00 am a 06:00 pm"}</p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"01(343) 427 0322"}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={1}>
            <div className={classes.container_footer}>
              <div>
                <p style={{ margin: 0, color: "#fff", fontSize: 35, fontWeight: 600, fontFamily: "m_bold" }}>{"Más"}</p>
                <div style={{ height: 60 }} />
                <p
                  onClick={() => history.push(`/${paths.usPath}`)}
                  style={{ margin: 3, color: "#fff", fontFamily: "m_regular", cursor: "pointer" }}
                >
                  {paths.usTitle}
                </p>
                <p
                  onClick={() => history.push(`/${paths.soccerPath}`)}
                  style={{ margin: 3, color: "#fff", fontFamily: "m_regular", cursor: "pointer" }}
                >
                  {paths.soccerTitle}
                </p>
                <p
                  onClick={() => history.push(`/${paths.basketPath}`)}
                  style={{ margin: 3, color: "#fff", fontFamily: "m_regular", cursor: "pointer" }}
                >
                  {paths.basketTitle}
                </p>
                <p
                  onClick={() => history.push(`/${paths.voleyPath}`)}
                  style={{ margin: 3, color: "#fff", fontFamily: "m_regular", cursor: "pointer" }}
                >
                  {paths.voleyTitle}
                </p>
                <p
                  onClick={() => history.push(`/${paths.contactPath}`)}
                  style={{ margin: 3, color: "#fff", fontFamily: "m_regular", cursor: "pointer" }}
                >
                  {paths.contactTitle}
                </p>
                <p
                  onClick={() => history.push(`/politicas-de-privacidad`)}
                  style={{ margin: 3, marginTop: 14, color: "#fff", fontFamily: "m_regular", cursor: "pointer" }}
                >
                  {"Políticas de privacidad"}
                </p>
                <p
                  onClick={() => history.push(`/politicas-de-devoluciones`)}
                  style={{ margin: 3, marginTop: 14, color: "#fff", fontFamily: "m_regular", cursor: "pointer" }}
                >
                  {"Políticas de devoluciones"}
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={2}>
            <div className={classes.container_footer}>
              <div>
                <p style={{ margin: 0, color: "#fff", fontSize: 35, fontWeight: 600, fontFamily: "m_bold" }}>
                  {"Planta"}
                </p>
                <div style={{ height: 60 }} />
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>
                  {"Carr. Sayula- San Gabriel Km 40.5"}
                </p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"Col. Cruz Verde CP 49700"}</p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"San Gabriel Jalisco"}</p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"343 427 0322"}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={3}>
            <div className={classes.container_footer}>
              <div>
                <p style={{ margin: 0, color: "#fff", fontSize: 35, fontWeight: 600, fontFamily: "m_bold" }}>
                  {"Oficina Comercial"}
                </p>
                <div style={{ height: 60 }} />
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"Av. Caña #3638"}</p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"Col. La Nogalera CP 44470"}</p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"Guadalajara, Jalisco"}</p>
                <p style={{ margin: 3, color: "#fff", fontFamily: "m_regular" }}>{"33 2005 0564"}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={4}>
            <div className={classes.container_footer}>
              <div>
                <p style={{ margin: 0, color: "#fff", fontSize: 35, fontWeight: 600, fontFamily: "m_bold" }}>
                  {"Promociones Exclusivas"}
                </p>
                <div style={{ height: 60 }} />
                <p style={{ margin: 0, color: "#fff", fontFamily: "m_regular" }}>
                  {"Suscribete para obtener promociones exclusivas."}
                </p>
                <br />
                <Subscribe />
              </div>
            </div>
          </Grid>
        </Grid>
        <div style={{ height: 50 }} />

        <Grid container>
          {/* <Grid item sm={12} md={4}>
                        <div style={{display:'flex', justifyContent:'center',}}>
                            <p style={{margin:0, color:'#fff', fontFamily:'m_regular'}}>{'Carretera Sayula – San Gabriel Km. 40.5'}</p>
                        </div>
                    </Grid> */}
          <Grid item sm={12} md={6}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="facebook"
                onClick={() => window.open("https://www.facebook.com/GaserMex", "_blank")}
                loading="lazy"
                src={Ic_facebook}
                style={{ padding: 10, width: 30, cursor: "pointer" }}
              />
              <img
                alt="youtube"
                onClick={() => window.open("https://www.youtube.com/channel/UCi-RlSxl3T0vyYfCXEeOvuA", "_blank")}
                loading="lazy"
                src={Ic_youtube}
                style={{ padding: 10, width: 30, cursor: "pointer" }}
              />
              <img
                alt="instagram"
                onClick={() => window.open("https://www.instagram.com/gasermex/", "_blank")}
                loading="lazy"
                src={Ic_instagram}
                style={{ padding: 10, width: 30, cursor: "pointer" }}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p style={{ margin: 0, color: "#fff", fontFamily: "m_regular" }}>
                {"Copyright © 2021 Gaser. All Rights Reserved."}
              </p>
            </div>
          </Grid>
        </Grid>
        <div style={{ height: 30 }} />
      </Container>
    </div>
  );
};

export default Footer;
