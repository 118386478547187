import { useState, useEffect } from "react";
import { Modal, Container, Grid, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import uniqid from "uniqid";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

// Redux
import { updateStore } from "redux/actions/Store";
// Values
import { Ic_unflod, paths, Ic_back, Ic_next, Ic_back_store } from "values";
// Styles
const useStyles = makeStyles((theme) => ({
  text_category: { margin: 0, color: "#929292" },
  text_name: { margin: 0, fontWeight: 700, fontSize: 30 },
  text_price: {
    fontSize: 30,
    fontWeight: 600,
    color: "#F36C22",
    margin: 0,
  },
  select: {
    cursor: "pointer",
    width: "100%",
    borderRadius: 5,
    height: 35,
    border: "none",
    fontSize: 15,
    paddingLeft: 10,
    paddingRight: 10,
    appearance: "none",
    background: `url(${Ic_unflod}) 96.5% / 11px no-repeat #f2f2f2`,
  },
  button: {
    background: "#f2f2f2",
    cursor: "pointer",
    borderRadius: 5,
    border: "none",
    fontSize: 18,
    height: 30,
    width: 30,
  },
  button_add: {
    background: "#f36c22",
    height: 45,
    cursor: "pointer",
    borderRadius: 12,
    border: "none",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 600,
    fontSize: 17,
    paddingLeft: 10,
    paddingRight: 10,
  },
  image_ball_modal_store: {
    width: "100%",
    maxHeight: 400,
    objectFit: "scale-down",
    [theme.breakpoints.down("xs")]: {
      maxHeight: 200,
    },
  },
  container_image: {
    background: "#f6f6f6",
    borderRadius: 13,
    margin: 10,
    height: 430,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 200,
    },
  },
}));
// Components
const Select = ({ value, setData, list }) => {
  const classes = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <select value={value} className={classes.select} onChange={(e) => setData(e.target.value)}>
        <option value="" disabled hidden>
          {"Selecciona un color"}
        </option>
        {list &&
          list.map((i, j) => (
            <option key={uniqid()} value={j} style={{ cursor: "pointer" }}>
              {i.color}
            </option>
          ))}
      </select>
    </div>
  );
};
const SelectSize = ({ value, setData, list }) => {
  const classes = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <select value={value} className={classes.select} onChange={(e) => setData(e.target.value)}>
        <option value="" disabled hidden>
          {"Selecciona el tamaño"}
        </option>
        {list &&
          list.map((i, j) => (
            <option key={uniqid()} value={j} style={{ cursor: "pointer" }}>
              {i.size}
            </option>
          ))}
      </select>
    </div>
  );
};

const IndividualProduct = ({ open, closeModal, data, store, updateStore }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [position, setPosition] = useState(0);
  const [position2, setPosition2] = useState(0);

  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");

  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState(0);
  const [sizeAux, setSizeAux] = useState(0);
  const [models, setModels] = useState([]);

  const [positionCarousel, setPositionCarousel] = useState(0);

  const [total, setTotal] = useState(1);

  const [positionImage, setPositionImage] = useState("0% 0%");
  const [display, setDisplay] = useState(false);

  const changeSize = (v) => {
    setPositionCarousel(0);
    setSizeAux(sizes[v].size);
    setSize(v);
    let auxPosition = 0;
    data.models.forEach((i, j) => {
      if (sizes[v].sku === i.sku) {
        auxPosition = j;
      }
    });
    setImage(data.models[auxPosition].picture);
    setImage2(data.models[auxPosition].picture2);
    setImage3(data.models[auxPosition].picture3);
    setPosition2(() => auxPosition);
  };

  const changePosition = (v) => {
    let auxSize = [];
    setPosition(v);
    setPosition2(0);
    setImage(models[v].picture);
    data.models.forEach((i, j) => {
      if (models[v].color === i.color) {
        auxSize.push({ size: i.size, sku: i.sku });
      }
    });
    setSizes(() => auxSize);
    setSize(0);
    setSizeAux(() => auxSize[0]);

    setPositionCarousel(0);
    let auxPosition = 0;
    data.models.forEach((i, j) => {
      if (auxSize[0].sku === i.sku) {
        auxPosition = j;
      }
    });

    setImage(data.models[auxPosition].picture);
    setImage2(data.models[auxPosition].picture2);
    setImage3(data.models[auxPosition].picture3);
  };

  const close = () => {
    setPosition(0);
    setPosition2(0);
    closeModal();
    setTotal(1);
  };

  const filterData = () => {
    let auxData = data.models;
    let auxSize = [];
    data.models.forEach((i) => {
      if (data.models[0].color === i.color) {
        auxSize.push({ size: i.size, sku: i.sku });
      }
    });
    const res = auxData.reduce((unique, o) => {
      if (!unique.some((obj) => obj.color === o.color)) {
        unique.push(o);
      }
      return unique;
    }, []);

    setModels(res);
    setSizes(() => auxSize);
    setSize(0);
    setSizeAux(() => auxSize[0]);
  };

  const decreaseTotal = () => {
    if (total - 1 !== 0) {
      setTotal(() => total - 1);
    }
  };

  const addProducts = () => {
    toast.success("Balón agregado al carrito.");
    let flag = false;
    store.forEach((i) => {
      if (i.id === data.id && i.color === models[position].color && i.size === parseInt(sizeAux)) {
        i.total += total;
        flag = true;
      }
    });
    if (!flag) {
      let storeAux = [];
      let auxData = {};
      auxData.category = data.category;
      auxData.collection = data.collection;
      auxData.id = data.id;
      auxData.line = data.line;
      auxData.name = data.name;
      auxData.price = data.models[position2].price;
      auxData.subBrand = data.subBrand;
      auxData.total = total;
      auxData.color = models[position].color;
      // auxData.size            = parseInt(size)
      auxData.size = data.models[position2].size;
      auxData.sku = models[position].sku;

      storeAux.push(auxData);
      store.forEach((i) => {
        storeAux.push(i);
      });
      updateStore(storeAux);
    } else {
      updateStore([...store]);
    }
  };

  const buyNow = () => {
    addProducts();
    setTimeout(() => history.push(`/${paths.cartPath}`), 600);
  };

  const handleMouseDisplay = () => {
    setDisplay(false);
    setPositionImage(`0% 0%`);
  };

  const handleMouseMove = (e) => {
    setDisplay(true);
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    // e.pageY => posicion del scroll
    // document.documentElement.scrollTop => posicion del mouse con el top de la pantalla
    const y = ((e.pageY - document.documentElement.scrollTop - top) / height) * 100;
    setPositionImage(`${x}% ${y}%`);
  };

  const changeImage = (position) => {
    setPositionCarousel(position);
  };

  const verifyCarousel = (newPos) => {
    if (newPos === 0) {
      return true;
    }
    if (newPos === 1) {
      if (image2) {
        return true;
      } else {
        return false;
      }
    }
    if (newPos === 2) {
      if (image3) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const nextImage = () => {
    if (positionCarousel === 0) {
      const res = verifyCarousel(positionCarousel + 1);
      if (res) {
        setPositionCarousel(positionCarousel + 1);
      }
    }
    if (positionCarousel === 1) {
      const res = verifyCarousel(positionCarousel + 1);
      if (res) {
        setPositionCarousel(positionCarousel + 1);
      }
    }
  };

  const backImage = () => {
    if (positionCarousel === 1) {
      const res = verifyCarousel(positionCarousel - 1);
      if (res) {
        setPositionCarousel(positionCarousel - 1);
      }
    }
    if (positionCarousel === 2) {
      const res = verifyCarousel(positionCarousel - 1);
      if (res) {
        setPositionCarousel(positionCarousel - 1);
      }
    }
  };

  useEffect(() => {
    if (data.models) {
      filterData();
      setImage(data.models[0].picture);
      setImage2(data.models[0].picture2);
      setImage3(data.models[0].picture3);
      setPositionCarousel(0);
    }
  }, [data.models]);

  return (
    <>
      {data.models && (
        <div open={open} onClose={close} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Container>
            <img alt="back" src={Ic_back_store} onClick={close} style={{ cursor: "pointer", marginTop: 30 }} />
            <div style={{ background: "#fff", width: "100%" }}>
              <Container maxWidth="xl" style={{ paddingTop: 20 }}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", position: "relative" }}>
                      <div className={classes.container_image}>
                        <img
                          alt=""
                          src={positionCarousel === 0 ? image : positionCarousel === 1 ? image2 : image3}
                          onMouseMove={handleMouseMove}
                          onMouseLeave={handleMouseDisplay}
                          className={classes.image_ball_modal_store}
                          style={{ position: "absolute", zIndex: 1, opacity: display ? 0 : 1, borderRadius: 50 }}
                        />
                      </div>
                      <div
                        style={{
                          backgroundPosition: positionImage,
                          backgroundRepeat: "no-repeat",
                          width: "90%",
                          height: "90%",
                          marginTop: 20,
                          backgroundImage: `url(${
                            positionCarousel === 0 ? image : positionCarousel === 1 ? image2 : image3
                          })`,
                          position: "absolute",
                          zIndex: 0,
                          opacity: display ? 1 : 0,
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "calc(100% - 20px)",
                        paddingBottom: 10,
                        marginLeft: 10,
                      }}
                    >
                      <img alt="" src={Ic_back} onClick={backImage} style={{ cursor: "pointer" }} />
                      <div style={{ display: "flex" }}>
                        <div style={{ width: 20 }} />
                        <p
                          onClick={() => changeImage(0)}
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            color: positionCarousel === 0 ? "#000" : "#c0c0c0",
                          }}
                        >
                          {image && 1}
                        </p>
                        <div style={{ width: 20 }} />

                        <p
                          onClick={() => changeImage(1)}
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            color: positionCarousel === 1 ? "#000" : "#c0c0c0",
                          }}
                        >
                          {image2 && 2}
                        </p>
                        <div style={{ width: 20 }} />

                        <p
                          onClick={() => changeImage(2)}
                          style={{
                            fontWeight: 600,
                            cursor: "pointer",
                            color: positionCarousel === 2 ? "#000" : "#c0c0c0",
                          }}
                        >
                          {image3 && 3}
                        </p>
                      </div>
                      <img alt="" src={Ic_next} onClick={nextImage} style={{ cursor: "pointer" }} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: "flex", alignItems: "center", height: "100%", paddingBottom: 20 }}>
                      <div style={{ width: "100%" }}>
                        <p className={classes.text_category} style={{ fontFamily: "m_regular" }}>
                          {data.category === "SOCCER"
                            ? "Fútbol"
                            : data.category === "BASKET"
                            ? "Básquetbol"
                            : "Vóleibol"}
                        </p>
                        <p className={classes.text_name} style={{ fontFamily: "m_semi_bold", margin: "6px 0" }}>
                          {data.name}
                        </p>
                        <p className={classes.text_category} style={{ fontFamily: "m_regular" }}>
                          {data.line === "TRAINIG" ? "Entrenamiento" : "Premium"} | {data.models[position].material}
                        </p>
                        <div style={{ height: 20 }} />
                        <p
                          className={classes.text_price}
                          style={{ fontFamily: "m_semi_bold" }}
                        >{`$${data.models[position2].price}`}</p>
                        <div style={{ height: 20 }} />
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "100%" }}>
                            <label style={{ fontFamily: "m_regular" }}>{"Color"}</label>
                            <Select value={position} setData={changePosition} list={models} />
                          </div>
                          <div style={{ width: 20 }} />
                          <div style={{ width: "100%" }}>
                            <label style={{ fontFamily: "m_regular" }}>{"Tamaño"}</label>
                            <SelectSize value={size} setData={changeSize} list={sizes} />
                          </div>
                        </div>
                        <div style={{ height: 30 }} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            onClick={decreaseTotal}
                            className={classes.button}
                            style={{ fontFamily: "m_semi_bold" }}
                          >
                            {"-"}
                          </button>
                          <div style={{ width: 50, textAlign: "center" }}>
                            <label style={{ fontWeight: 600, fontSize: 22, fontFamily: "m_semi_bold" }}>{total}</label>
                          </div>
                          <button
                            onClick={() => setTotal(total + 1)}
                            style={{ fontFamily: "m_semi_bold" }}
                            className={classes.button}
                          >
                            {"+"}
                          </button>
                          <div style={{ width: 10 }} />
                          <button
                            onClick={addProducts}
                            style={{ fontFamily: "m_semi_bold" }}
                            className={classes.button_add}
                          >
                            {"Agregar a carrito"}
                          </button>
                          <div style={{ width: 10 }} />
                          <button
                            onClick={buyNow}
                            style={{ fontFamily: "m_semi_bold", background: "#2153a4" }}
                            className={classes.button_add}
                          >
                            {"Comprar ahora"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
});

export default connect(mapStateToProps, { updateStore })(IndividualProduct);
