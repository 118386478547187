// Styles
import {useStyles}  from 'style';
const Hashtag = () => {
    const classes = useStyles()
    return (
        <div style={{backgroundColor:'#000', paddingTop:60, paddingBottom:60, textAlign:'center'}}>
            <h1 className={classes.hashtag_text} style={{fontFamily:'marker'}}>
                {'#Elcorazóndeljuego'}
            </h1>
        </div>
    );
};

export default Hashtag;