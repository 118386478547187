import {reduxState} from 'values';

let initialState = {
    name:       localStorage.getItem('gaser-name'),
    lastName:   localStorage.getItem('gaser-lastname'),
    picture:    localStorage.getItem('gaser-picture'),
    rol:        localStorage.getItem('gaser-rol'),
    token:      localStorage.getItem('gaser-key'),
    email:      localStorage.getItem('gaser-email'),
    data:       [],
    sales:      []
}

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.login){
        return {
            ...state,
            name:       action.name,
            lastName:   action.lastName,
            picture:    action.picture,
            rol:        action.rol,
            token:      action.token,
            email:      action.email
        }
    }
    if(action.type === reduxState.refresh){
        return {
            ...state,
            token: action.token,
        }
    }
    if(action.type === reduxState.get_user){
        return {
            ...state,
            data: action.data,
        }
    }
    if(action.type === reduxState.update_picture){
        return {
            ...state,
            picture: action.picture,
        }
    }
    if(action.type === reduxState.update_user){
        return {
            ...state,
            name:       action.name,
            lastName:   action.lastName,
        }
    }
    if(action.type === reduxState.get_my_sales){
        return {
            ...state,
            sales:  action.data,
        }
    }
    return state
}
export default reducer