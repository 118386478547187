import React, { useState } from 'react';
import ButtonPayment    from '../global/ButtonPayment';

const ButtonPay = ({paymentCart}) => {
    const [loading, setLoading] = useState(false)
    const onSubmit = () =>{
        setLoading(true)
        paymentCart()
    }
    const standBy = () =>{}
    return (
        <div style={{display:'flex', justifyContent:'center', marginTop:10, marginBottom:20}}>
            <div style={{width:500}}>
                <ButtonPayment onClick={loading?standBy:onSubmit} loading={loading} />
            </div>
        </div>
    );
};

export default ButtonPay;