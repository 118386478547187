import { useState, useEffect, Fragment } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";

// Values
import { Ic_back_store } from "values";
// Styles
const useStyles = makeStyles((theme) => ({
  text_category: { margin: 0, color: "#929292" },
  text_name: { margin: 0, fontWeight: 700, fontSize: 30 },
  text_price: {
    fontSize: 30,
    fontWeight: 600,
    color: "#F36C22",
    margin: 0,
  },
  image_ball_modal_store: {
    width: 500,
    // maxHeight: 400,
    objectFit: "scale-down",
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  image_ball: {
    animation: "$selected_ball 0.4s both",
  },
  "@keyframes selected_ball": {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-15px)",
    },
  },
}));

const Picture = ({ img, value, selected, onAction }) => {
  const classes = useStyles();
  return (
    <>
      <img
        onClick={onAction}
        className={value === selected ? classes.image_ball : ""}
        alt=""
        src={img}
        style={{
          cursor: "pointer",
          width: 60,
          padding: "0 3px",
          paddingBottom: 8,
          // transform: `translateY(${value === selected ? "-10px" : 0})`,
          borderBottom: `5px solid ${value === selected ? "#F36C22" : "#fff"}`,
        }}
      />
    </>
  );
};

const Dot = ({ onAction, selected }) => {
  return (
    <div
      onClick={onAction}
      style={{
        width: 15,
        height: 15,
        background: selected ? "#c0c0c0" : "#e8e6e6",
        borderRadius: "100%",
        cursor: "pointer",
      }}
    />
  );
};

const NewIndividualProducts = ({ open, closeModal, data }) => {
  const classes = useStyles();

  const [position, setPosition] = useState(0);
  const [position2, setPosition2] = useState(0);

  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");

  const [models, setModels] = useState([]);

  const close = () => {
    setPosition(0);
    setPosition2(0);
    closeModal();
  };

  const filterData = () => {
    let auxData = data.models;
    let auxSize = [];
    data.models.forEach((i) => {
      if (data.models[0].color === i.color) {
        auxSize.push({ size: i.size, sku: i.sku });
      }
    });
    const res = auxData.reduce((unique, o) => {
      if (!unique.some((obj) => obj.color === o.color)) {
        unique.push(o);
      }
      return unique;
    }, []);

    setModels(res);
  };

  const changePosition = (v) => {
    let auxSize = [];
    setPosition(v);
    setPosition2(0);
    setImage(models[v].picture);
    data.models.forEach((i, j) => {
      if (models[v].color === i.color) {
        auxSize.push({ size: i.size, sku: i.sku });
      }
    });

    let auxPosition = 0;
    data.models.forEach((i, j) => {
      if (auxSize[0].sku === i.sku) {
        auxPosition = j;
      }
    });

    setImage(data.models[auxPosition].picture);
    setImage2(data.models[auxPosition].picture2);
    setImage3(data.models[auxPosition].picture3);
  };

  useEffect(() => {
    if (data.models) {
      filterData();
      setImage(data.models[0].picture);
      setImage2(data.models[0].picture2);
      setImage3(data.models[0].picture3);
    }
  }, [data.models]);
  return (
    <Fragment>
      {data.models && (
        <div open={open} onClose={close} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Container>
            <img alt="back" src={Ic_back_store} onClick={close} style={{ cursor: "pointer", marginTop: 30 }} />
            <Container maxWidth="xl" style={{ paddingTop: 20 }}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src={position2 === 0 ? image : position2 === 1 ? image2 : image3}
                      className={classes.image_ball_modal_store}
                      style={{ borderRadius: 50 }}
                    />
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 10, gap: 10 }}>
                      {image && <Dot selected={0 === position2} onAction={() => setPosition2(0)} />}
                      {image2 && <Dot selected={1 === position2} onAction={() => setPosition2(1)} />}
                      {image3 && <Dot selected={2 === position2} onAction={() => setPosition2(2)} />}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                    <p className={classes.text_name} style={{ fontFamily: "m_semi_bold", margin: "6px 0" }}>
                      {data.name}
                    </p>
                    <p className={classes.text_category} style={{ fontFamily: "m_regular" }}>
                      {data.line === "TRAINIG" ? "Entrenamiento" : "Premium"} | {data.models[position2]?.material}
                    </p>

                    <div style={{ marginTop: 30, display: "flex", gap: 10 }}>
                      {models.map((item, index) => (
                        <Picture
                          key={`balon-${item}`}
                          img={item.picture}
                          value={index}
                          selected={position}
                          onAction={() => changePosition(index)}
                        />
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </div>
      )}
    </Fragment>
  );
};

export default NewIndividualProducts;
