
// Styles
import {useStyles}  from 'style';
const Input = ({title='Title', value, setValue, placeholder='', type='text'}) => {
    const classes = useStyles()
    return (
        <div style={{width:'100%'}}>
            <label style={{margin:0}}>{title}</label>
            <input className={classes.input} value={value} onChange={e=>setValue(e.target.value)}
                type={type} style={{background:'#f7f7f7'}}
                placeholder={placeholder} />
        </div>
    );
};

export default Input;