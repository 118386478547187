import {Container, Grid}  from '@material-ui/core';

import Form from './Form';

// Styles
import {useStyles}  from '../../style';
const Banner = () => {
    const classes = useStyles()
    return (
        <div className={classes.container_contact_main}>
            <Container maxWidth='xl'>
                <div className={classes.container_contact}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={5}>
                            <div style={{display:'flex', alignItems:'center', height:'70%'}}>
                                <div style={{width:'100%'}}>
                                    <h2 style={{fontSize:50, color:'#fff', margin:0, fontFamily:'m_bold'}}>{'Contacta con nuestro equipo.'}</h2>
                                    <p style={{margin:0, color:'#fff', fontFamily:'m_regular', marginTop:23}}>{'Con gusto responderemos a tus preguntas y te ayudaremos a encontrar el producto que necesitas.'}</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7}>
                            <Container style={{padding:0}}>
                                <div style={{width:'100%', background:'#fff', position:'relative', boxShadow:'0px 0px 20px 0px rgba(0,0,0,0.1)', marginTop:50}} >
                                    <Form />
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
};

export default Banner;