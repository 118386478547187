import back_us_a from "images/back_us_a.svg";
import back_us_b from "images/back_us_b.svg";
import background_profile from "images/background_profile.jpg";
import ball from "images/ball.png";
import banner from "images/banner.jpg";
import basket from "images/basket.png";
import basket_min from "images/basket-min.jpg";
import ic_back from "images/ic_back.svg";
import ic_back_store from "images/ic_back_store.svg";
import ic_call from "images/ic_call.svg";
import ic_camera from "images/ic_camera.svg";
import ic_card from "images/ic_card.svg";
import ic_card_white from "images/ic_card_white.svg";
import ic_cart from "images/ic_cart.svg";
import ic_change_option from "images/ic_change_option.svg";
import ic_check from "images/ic_check.svg";
import ic_edit from "images/ic_edit.svg";
import ic_facebook from "images/ic_facebook.svg";
import ic_facebook_black from "images/ic_facebook_black.svg";
import ic_instagram from "images/ic_instagram.svg";
import ic_instagram_black from "images/ic_instagram_black.svg";
import ic_less from "images/ic_less.svg";
import ic_marker_map from "images/ic_marker_map.png";
import ic_menu from "images/ic_menu.svg";
import ic_more from "images/ic_more.svg";
import ic_mouse from "images/ic_mouse.svg";
import ic_next from "images/ic_next.svg";
import ic_oxxo from "images/ic_oxxo.svg";
import ic_oxxo_white from "images/ic_oxxo_white.svg";
import ic_paypal from "images/ic_paypal.svg";
import ic_paypal_white from "images/ic_paypal_white.svg";
import ic_remove from "images/ic_remove.svg";
import ic_spei from "images/ic_spei.svg";
import ic_spei_white from "images/ic_spei_white.svg";
import ic_twitter from "images/ic_twitter.svg";
import ic_uncheck from "images/ic_uncheck.svg";
import ic_unflod from "images/ic_unflod.svg";
import ic_user from "images/ic_user.svg";
import ic_visibility from "images/ic_visibility.svg";
import ic_visibility_off from "images/ic_visibility_off.svg";
import ic_web_black from "images/ic_web_black.svg";
import ic_youtube from "images/ic_youtube.svg";
import image_a from "images/image_a.png";
import image_b from "images/image_b.png";
import img_follow from "images/img_follow.png";
import img_gallery_a from "images/img_gallery_a.webp";
import img_gallery_b from "images/img_gallery_b.webp";
import img_gallery_c from "images/img_gallery_c.webp";
import img_gallery_d from "images/img_gallery_d.webp";
import img_gallery_e from "images/img_gallery_e.webp";
import img_gallery_f from "images/img_gallery_f.webp";
import img_gallery_g from "images/img_gallery_g.webp";
import img_gaser_habit from "images/img_gaser_habit.svg";
import img_logo_login from "images/img_logo_login.svg";
import img_partners from "images/img_partners.jpg";
import img_promotion from "images/img_promotion.svg";
import img_replace_a from "images/img_replace_a.svg";
import img_replace_b from "images/img_replace_b.svg";
import logo_big from "images/logo_big.svg";
import logo_small from "images/logo_small.svg";
import main_back_a from "images/main_back_a.png";
import main_back_b from "images/main_back_b.png";
import main_back_c from "images/main_back_c.png";
import main_front_a from "images/main_front_a.png";
import main_front_b from "images/main_front_b.png";
import main_front_c from "images/main_front_c.png";
import picture_rectangle_a from "images/picture_rectangle_a.jpg";
import soccer from "images/soccer.png";
import soccer_min from "images/soccer-min.jpg";
import txt_basket from "images/txt_basket.png";
import txt_soccer from "images/txt_soccer.png";
import txt_volley from "images/txt_volley.png";
import us_ball from "images/us_ball.png";
import us_banner from "images/us_banner.jpg";
import user from "images/user.jpg";
import volley from "images/volley.png";
import volley_min from "images/volley-min.jpg";

// Lottie
import gaser from "lottie/gaser.json";
import smile_emoji from "lottie/smile-emoji.json";
import field from "lottie/field.json";

// Images
export const Back_us_a = back_us_a;
export const Back_us_b = back_us_b;
export const Background_profile = background_profile;
export const Ball = ball;
export const Banner_img = banner;
export const Basket = basket;
export const Basket_min = basket_min;
export const Ic_back = ic_back;
export const Ic_back_store = ic_back_store;
export const Ic_call = ic_call;
export const Ic_camera = ic_camera;
export const Ic_card = ic_card;
export const Ic_card_white = ic_card_white;
export const Ic_cart = ic_cart;
export const Ic_change_option = ic_change_option;
export const Ic_check = ic_check;
export const Ic_edit = ic_edit;
export const Ic_facebook = ic_facebook;
export const Ic_facebook_black = ic_facebook_black;
export const Ic_instagram = ic_instagram;
export const Ic_instagram_black = ic_instagram_black;
export const Ic_less = ic_less;
export const Ic_marker_map = ic_marker_map;
export const Ic_menu = ic_menu;
export const Ic_more = ic_more;
export const Ic_mouse = ic_mouse;
export const Ic_next = ic_next;
export const Ic_oxxo = ic_oxxo;
export const Ic_oxxo_white = ic_oxxo_white;
export const Ic_paypal = ic_paypal;
export const Ic_paypal_white = ic_paypal_white;
export const Ic_remove = ic_remove;
export const Ic_spei = ic_spei;
export const Ic_spei_white = ic_spei_white;
export const Ic_twitter = ic_twitter;
export const Ic_uncheck = ic_uncheck;
export const Ic_unflod = ic_unflod;
export const Ic_user = ic_user;
export const Ic_visibility = ic_visibility;
export const Ic_visibility_off = ic_visibility_off;
export const Ic_web_black = ic_web_black;
export const Ic_youtube = ic_youtube;
export const Image_a = image_a;
export const Image_b = image_b;
export const Img_follow = img_follow;
export const Img_gallery_a = img_gallery_a;
export const Img_gallery_b = img_gallery_b;
export const Img_gallery_c = img_gallery_c;
export const Img_gallery_d = img_gallery_d;
export const Img_gallery_e = img_gallery_e;
export const Img_gallery_f = img_gallery_f;
export const Img_gallery_g = img_gallery_g;
export const Img_gaser_habit = img_gaser_habit;
export const Img_logo_login = img_logo_login;
export const Img_partners = img_partners;
export const Img_promotion = img_promotion;
export const Img_replace_a = img_replace_a;
export const Img_replace_b = img_replace_b;
export const Logo_big = logo_big;
export const Logo_small = logo_small;
export const Main_back_a = main_back_a;
export const Main_back_b = main_back_b;
export const Main_back_c = main_back_c;
export const Main_front_a = main_front_a;
export const Main_front_b = main_front_b;
export const Main_front_c = main_front_c;
export const Picture_rectangle_a = picture_rectangle_a;
export const Soccer = soccer;
export const Soccer_min = soccer_min;
export const Txt_basket = txt_basket;
export const Txt_soccer = txt_soccer;
export const Txt_volley = txt_volley;
export const Us_ball = us_ball;
export const Us_banner = us_banner;
export const User = user;
export const Volley = volley;
export const Volley_min = volley_min;

// Lotie
export const Gaser = gaser;
export const Smile_emoji = smile_emoji;
export const Field = field;

//
// ─── TOAST TEXT ─────────────────────────────────────────────────────────────────
//
export const toastText = {
  update: "Datos actualizados.",
  add: "Datos agregados.",
  delete: "Datos eliminados.",
  warning: "Parace que surgio un problema.",
  void: "Parace que faltan campos por llenar.",
  password: "Las contraseñas coinciden.",
  failLogin: "No se pudo iniciar sesión, verifica tus datos.",
  successLogin: "Iniciando sesión",
};

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
export const stateData = {
  state1: "DOWNLOADING",
  state2: "DOWNLOADED",
};

//
// ─── STATE OF REDUX ─────────────────────────────────────────────────────────────
//
export const reduxState = {
  drawer: "UPDATE_DRAWER",
  view: "CHANGE_VIEW",
  login: "LOGIN",
  refresh: "REFRESH",
  change_flag_path: "CHANGE_FLAG_PATH",
  get_alliances: "GET_ALLIANCES",
  get_banners: "GET_BANNERS",
  get_dealers: "GET_DEALERS",
  get_my_sales: "GET_MY_SALES",
  get_products: "GET_PRODUCTS",
  get_setup: "GET_SETUP",
  get_user: "GET_USER",
  store: "STORE",
  update_picture: "UPDATE_PICTURE",
  update_user: "UPDATE_USER",
};

export const cfdiList = ["Gastos en general", "Adquisición de mercancia"];

export const paths = {
  homeTitle: "Inicio",
  homePath: "",
  usTitle: "Nosotros",
  usPath: "nosotros",
  soccerTitle: "Fútbol",
  soccerPath: "futbol",
  basketTitle: "Básquetbol",
  basketPath: "basketbol",
  voleyTitle: "Vóleibol",
  voleyPath: "voleibol",
  cartTitle: "Carrito",
  cartPath: "carrito",
  panelTitle: "Panel",
  panelPath: "panel",
  contactTitle: "Contacto",
  contactPath: "contacto",
  alliancesTitle: "Alianzas",
  alliancesPath: "alianzas",
};
