
// Styles
import {useStyles}  from '../../style';
const Input = ({title='Title', value, setValue, placeholder='Placeholder', requiered=false, type='text'}) => {
    const classes = useStyles()
    return (
        <div>
            <label style={{fontWeight:600, margin:0, marginLeft:15, fontFamily:'m_semi_bold'}}>{title}</label>
            {
                requiered&&
                <>
                    <label style={{fontWeight:600, margin:0, color:'#ff1771', marginLeft:5}}>{'*'}</label>
                </>
            }
            <br/>
            {
                type==='area'?
                <textarea value={value} onChange={e=>setValue(e.target.value)} style={{fontFamily:'m_regular'}}
                    className={classes.input_area} placeholder={placeholder} />
                :
                <input className={classes.input} 
                    value={value} onChange={e=>setValue(e.target.value)} style={{fontFamily:'m_regular'}}
                    type={type} 
                    placeholder={placeholder} />
            }
            
        </div>
    );
};

export default Input;