import {Container, Grid}    from '@material-ui/core';
import {connect}            from 'react-redux';

// Styles
import {useStyles}  from 'style';
// Value
import {Ic_web_black, Ic_facebook_black, Ic_instagram_black} from 'values';
// import partners from 'values/partners'

const Card = ({image, title, facebook, instagram, web}) =>{
    const classes = useStyles()
    return(
        <div className={classes.container_alliances_card}>
            <div className={classes.content_alliances_card_image}>
                <img className={classes.content_alliances_card_image} alt={title} title={title} src={image} />
            </div>
            <div style={{padding:30, display:'flex', alignItems:'center'}}>
                <div>
                    <p className={classes.title_card_alliances}>{title}</p>
                    <div style={{display:'flex'}}>
                        <img alt=''  onClick={()=>window.open(facebook, '_blank')} style={{display:facebook?'block':'none', cursor:'pointer', width:25, margin:10}} src={Ic_facebook_black} />
                        <img alt=''  onClick={()=>window.open(instagram, '_blank')} style={{display:instagram?'block':'none', cursor:'pointer', width:25, margin:10}} src={Ic_instagram_black} />
                        <img alt=''  onClick={()=>window.open(web, '_blank')} style={{display:web?'block':'none', cursor:'pointer', width:25, margin:10}} src={Ic_web_black} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Cards = ({data}) => {
    return (
        <div style={{padding:'70px 0'}}>
            <Container>
                <Grid container>
                    
                {
                    data&&
                    data.map((i, j)=>(
                        <Grid item xs={12} lg={6} key={j}>
                            <Card image={i.image} title={i.name}
                                facebook={i.facebook} instagram={i.instagram} web={i.web}  />
                        </Grid>
                    ))
                }
                
                </Grid>
            </Container>
        </div>
    );
};

const mapStateToProps = state =>({
    data: state.Alliances.data
})

export default connect(mapStateToProps, {})(Cards)
