import {Fragment} from 'react';
import {useState} from 'react';
import {Container, Collapse, Grid} from '@material-ui/core';
import {connect} from 'react-redux';

// Utils
import {formatDate3, formatMoney} from 'utils';
// Styles
import {useStyles} from 'style';

const CardRecords = ({data, index, open, setOpen}) =>{
    const classes   = useStyles();

    const calculateSubtotal = () =>{
        let auxSub = 0
        data.products.forEach(i=>{
            auxSub += i.total*i.price
        })
        return auxSub
    }
    const calculateDiscount = () =>{
        let subTotal = calculateSubtotal()
        let auxTotal = 0
        if(data.discountType==='PERCENT'){ auxTotal = (subTotal*(data.discountValue/100)) } 
        else{ auxTotal = data.discountValue }
        return auxTotal
    }
    return(
        <div style={{boxShadow:'0px 0px 10px -4px rgba(0,0,0,0.35)', borderRadius:15, cursor:'pointer'}} onClick={()=>setOpen(index, !open[index])}>
            <Container>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:25, marginTop:15, paddingTop:15, flexWrap:'wrap'}}>
                    <p style={{fontWeight:600, fontSize:20, margin:0, fontFamily:'m_semi_bold'}}>{`Compra: ${formatDate3(data.date)}`}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{`$ ${formatMoney(data.total)}`}</p>
                </div>
                <p style={{margin:0, fontFamily:'m_regular'}}>{data.name} {data.lastName}</p>
                <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.email}</p>
                    <p style={{margin:0, fontWeight:700,fontFamily:'m_semi_bold', color:data.orderStatus==='CREATED'?'#F36C22':data.orderStatus==='PAID'?'#2354a4':data.orderStatus==='EXPIRED'? '#000':data.orderStatus==='SENT'?'#2ed37c':''}}>
                        {
                        data.orderStatus==='CREATED'?'Orden creada':data.orderStatus==='PAID'?'Orden pagada':data.orderStatus==='EXPIRED'? 'Orden expirada':data.orderStatus==='SENT'?'Enviado':''
                        }
                    </p>
                </div>
                <div style={{height:20}} />
            </Container>
            
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:45, background:data.orderStatus==='CREATED'?'#F36C22':data.orderStatus==='PAID'?'#2354a4':data.orderStatus==='EXPIRED'? '#000':data.orderStatus==='SENT'?'#2ed37c':''}}>
                    <p className={classes.my_records_title} style={{fontFamily:'m_bold'}}>
                        {
                            data.orderStatus==='CREATED'?'Realiza tu pago para finalizar tu compra.':
                            data.orderStatus==='PAID'?'Orden pagada':
                            data.orderStatus==='EXPIRED'? 'El tiempo para pagar esta orden ha vencido sera necesario crear una nueva.':
                            data.orderStatus==='SENT'?'Enviado':''
                        }
                    </p>
                </div>
                {
                    data.guide?
                        <div style={{background:'#f7f7f7'}}>
                            <Container style={{paddingTop:15, paddingBottom:15}}>
                                <Grid container>
                                    <Grid item xs={12} md={2}>
                                        <p style={{fontWeight:600, margin:0, fontFamily:'m_semi_bold'}}>{'Empresa'}</p>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <p style={{margin:0, fontFamily:'m_regular'}}>
                                            {data.deliveryCompany}</p>
                                    </Grid>

                                    <Grid item xs={12} md={2}>
                                        <p style={{fontWeight:600, margin:0, fontFamily:'m_semi_bold'}}>{'Número de guía'}</p>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <p style={{margin:0, fontFamily:'m_regular'}}>{data.guide}</p>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    :
                    data.orderStatus==='PAID'?
                        <div style={{background:'#f7f7f7', textAlign:'center'}}>
                            <Container style={{paddingTop:15, paddingBottom:15}}>
                                <p style={{fontWeight:600, margin:0, fontSize:20}}>
                                    {'Estamos procesando tu pedido, recibiras un correo cuando este en camino.'}
                                </p>
                            </Container>
                        </div>
                    :
                    <div />
                }
                <Container>
                    <p style={{fontWeight:600, fontFamily:'m_semi_bold'}}>{'PEDIDO'}</p>
                    <Grid container>
                        {
                            data.products&&
                            data.products.map((i,j) =>(
                                <Fragment key={j} >
                                    <Grid item xs={12} sm={8} md={10}>
                                        <div style={{display:'flex'}}>
                                            <p style={{margin:0, fontWeight:600, width:50, fontFamily:'m_semi_bold'}}>{i.total}</p>
                                            <p style={{margin:0, fontFamily:'m_regular'}}>{i.name} #{i.size} {i.color}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={2} md={1}>
                                        <p className={classes.info_text_record}>{'Precio c/u'}</p>
                                        <p style={{margin:0, fontWeight:600, fontFamily:'m_semi_bold'}}>{`$${formatMoney(i.price)}`}</p>
                                    </Grid>
                                    <Grid item xs={6} sm={2} md={1}>
                                        <p className={classes.info_text_record}>{'Total'}</p>
                                        <p style={{margin:0, fontWeight:600, fontFamily:'m_semi_bold'}}>{`$${formatMoney(i.price*i.total)}`}</p>
                                    </Grid>
                                    <div className={classes.space_records} />
                                </Fragment>
                            ))
                        }
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={12} sm={8} md={10}></Grid>
                        <Grid item xs={6} sm={2} md={1}>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{'Subtotal'}</p>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{'Descuento'}</p>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{'Envio'}</p>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{'Total'}</p>
                        </Grid>
                        <Grid item xs={6} sm={2} md={1}>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{`$${formatMoney(calculateSubtotal())}`}</p>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{`$${formatMoney(calculateDiscount())}`}</p>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{`$${formatMoney(data.delivery)}`}</p>
                            <p style={{margin:0, fontFamily:'m_regular'}}>{`$${formatMoney(data.total)}`}</p>
                        </Grid>
                    </Grid>
                
                    <p style={{fontWeight:600,marginBottom:0, fontFamily:'m_semi_bold'}}>{'DATOS DE ENVIO'}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.address} {data.numExt?`#${data.numExt}`:''} {data.numInt?`#${data.numInt}`:''}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.area}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.municipality} {data.state}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.postalCode}</p>

                    <p style={{fontWeight:600,marginBottom:0, fontFamily:'m_semi_bold'}}>{'DATOS DE FACTURACIÓN'}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.rfc}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.legalName}</p>

                    <p style={{marginBottom:0, fontFamily:'m_semi_bold'}}>{data.invoiceAddress} {data.invoiceExt?`#${data.invoiceExt}`:''} {data.invoiceInt?`#${data.invoiceInt}`:''}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.invoiceArea}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.invoiceMunicipality} {data.invoiceState}</p>
                    <p style={{margin:0, fontFamily:'m_regular'}}>{data.invoicePostalCode}</p>

                    <p style={{marginBottom:0, fontFamily:'m_regular'}}>{data.invoiceEmail}</p>
                </Container>
                <div style={{height:20}} />
            </Collapse>
        </div>
    )
}

const Record = ({mySales}) => {
    const [open, setOpen] = useState([true, false, false, false])

    const changeOpen = (index, status) =>{
        let auxOpen = open
        auxOpen.forEach((i,j)=>{
            if(j===index){
                auxOpen[j] = status
            }
        })

        setOpen([...open], auxOpen)
    }
    return (
        <div>
            {/* <CardRecords status={'En espera de envio'} color='#2354a4' index={0} open={open} setOpen={changeOpen} />
            <CardRecords status={'En espera de pago'} color='#F36C22' index={1} open={open} setOpen={changeOpen} />
            <CardRecords status={'Validando pago'} color='#000' index={2} open={open} setOpen={changeOpen} />
            <CardRecords status={'Enviado'} color='#2ed37c' index={3} open={open} setOpen={changeOpen} /> */}
            {
                mySales&&
                mySales.map((i, j)=>(
                    <CardRecords key={j} data={i}
                        status={'En espera de envio'} 
                        index={j} 
                        open={open} setOpen={changeOpen} />
                ))
            }
        </div>
    );
};

const mapStateToProps = state =>({
    mySales: state.Panel.sales,
})

export default connect(mapStateToProps, {})(Record)