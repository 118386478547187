import { makeStyles } from "@material-ui/core/styles";

import { Ic_change_option } from "values";

export const useStyles = makeStyles((theme) => ({
  button_card: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    background: "#fffffe",
    width: 60,
    height: 50,
    borderRadius: 18,
    cursor: "pointer",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
    "&:active": {
      transform: "scale(0.96)",
    },
    // [theme.breakpoints.down('xs')]:{
    //     width:'calc(100% - 40px)',
    // },
  },
  path_menu: {
    color: "#fff",
    cursor: "pointer",
    userSelect: "none",
    position: "relative",
    fontSize: 18,
    position: "absolute",
    top: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 0,
  },
  container_menu: {
    background: "#2153a4",
    borderBottom: "1px solid #4670b3",
    padding: "16px 0",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  container_menu_drawer: {
    display: "none",
    background: "#2153a4",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  title1: {
    margin: 0,
    color: "#fff",
    textTransform: "uppercase",
  },
  title2: {
    margin: 0,
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 60,
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
    },
  },
  image_home: {
    width: 650,
    height: 650,
    objectFit: "scale-down",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: 650,
      height: 650,
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  image_home_back: {
    width: 650,
    height: 650,
    objectFit: "scale-down",
    position: "absolute",
    left: 0,
    top: 0,
    // zIndex:0,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: 650,
      height: 650,
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },

  image_home_back_animation: {
    width: 650,
    height: 650,
    objectFit: "scale-down",
    position: "absolute",
    left: 0,
    top: 0,
    animation: "$image_animation 1s ease-in-out both",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: 650,
      height: 650,
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  "@keyframes image_animation": {
    "0%": {
      opacity: 0,
      transform: "scale(0)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  banner_image_animation: {
    animation: "$banner_image_animation 1s ease both",
  },
  "@keyframes banner_image_animation": {
    "0%": {
      opacity: 0,
      transform: "translateX(150px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  text_card_sports: {
    position: "absolute",
    bottom: 15,
    left: "50%",
    transform: "translate(-50%, 0%)",
    fontSize: 43,
    textTransform: "uppercase",
  },
  title1_sports: {
    fontSize: 60,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  title1_sports2: {
    position: "absolute",
    top: -20,
    color: "#f36c22",
    textTransform: "uppercase",
    fontSize: 65,
    paddingLeft: 10,
    zIndex: 1,
  },
  button: {
    backgroundColor: "#000",
    color: "#fff",
    textTransform: "uppercase",
    width: 180,
    height: 60,
    cursor: "pointer",
    border: "none",
    borderRadius: 13,
    fontSize: 20,
    fontWeight: 600,
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  button_outline: {
    backgroundColor: "transparent",
    color: "#fff",
    textTransform: "uppercase",
    width: 130,
    height: 40,
    cursor: "pointer",
    border: "1px solid #fff",
    borderRadius: 20,
    fontSize: 18,
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  button_edit: {
    cursor: "pointer",
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  button_payment: {
    backgroundColor: "#2ed37c",
    color: "#fff",
    textTransform: "uppercase",
    width: "100%",
    height: 60,
    cursor: "pointer",
    border: "none",
    borderRadius: 13,
    fontSize: 20,
    fontWeight: 600,
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  card_ball: {
    backgroundColor: "#f6f6f6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 0,
    paddingTop: 0,
    marginLeft: 1,
    marginRight: 1,
    border: "2px solid #fff",
    height: "100%",
  },
  title_promotions: {
    color: "#fff",
    fontSize: 35,
    fontWeight: 700,
    textTransform: "uppercase",
    paddingTop: 6,
    paddingBottom: 6,
  },
  more_info_title1: {
    fontSize: 40,
  },
  container_images_scroll: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "absolute",
    top: 0,
    left: 0,
    animation: "$bannermove 50s linear infinite",
  },
  "@keyframes bannermove": {
    "0%": {
      transform: "translate(0, 0)",
    },
    "100%": {
      transform: "translate(-50%, 0)",
    },
  },
  input_subscribe: {
    width: "100%",
    // height:35,
    paddingLeft: 10,
    paddingRight: 10,
    border: "none",
    background: "#2A4F8B",
    color: "#fff",
    // paddingBottom:10,
    fontSize: 20,
  },
  button_subscribe: {
    background: "#F36C22",
    color: "#fff",
    cursor: "pointer",
    border: "none",
    fontSize: 22,
    height: 50,
    width: 200,
    height: 0,
    padding: "0 20px",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  line_menu: {
    background: "#F36C22",
    margin: 35,
    position: "absolute",
    top: -45,
    zIndex: 0,
    transition: "height 0.35s ease-in-out",
    transformOrigin: "bottom",
  },
  us_ball: {
    width: 450,
    position: "relative",
    marginLeft: -50,
    animation: "$us_ball_animation .7s linear both",
    [theme.breakpoints.down("lg")]: {
      width: 350,
      marginLeft: 80,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  "@keyframes us_ball_animation": {
    "0%": {
      // opacity: 0,
      transform: "translateX(-700px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  back_us_a: {
    position: "absolute",
    top: "5%",
    left: "6%",
    width: 750,
    animation: "$back_us_animation 1s linear both",
    [theme.breakpoints.down("lg")]: {
      width: 500,
      left: "3%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      left: 0,
    },
  },
  back_us_b: {
    position: "absolute",
    bottom: -80,
    right: 0,
    width: 500,
    animation: "$back_us_animation 1s linear both",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // left:'3%'
    },
  },
  "@keyframes back_us_animation": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  us_title1: {
    fontSize: 40,
    fontWeight: 600,
    margin: 0,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 35,
    },
  },
  us_title2: {
    fontSize: 40,
    fontWeight: 600,
    margin: 0,
    textTransform: "uppercase",
    color: "#F36C22",
    [theme.breakpoints.down("sm")]: {
      fontSize: 35,
    },
  },
  us_title_values: {
    fontSize: 260,
    margin: 0,
    textTransform: "uppercase",
    position: "absolute",
    top: 90,
    color: "#f1f1f1",
    [theme.breakpoints.down("lg")]: {
      fontSize: 250,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 200,
      top: 110,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 150,
      top: 180,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 60,
      top: -80,
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  us_value_space: {
    height: 110,
    [theme.breakpoints.down("xs")]: {
      height: 0,
    },
  },
  us_info: {
    margin: 15,
    fontSize: 20,
  },
  input: {
    width: "calc(100% - 10px)",
    fontSize: 16,
    backgroundColor: "#f7f7f7",
    border: "none",
    borderRadius: 5,
    paddingLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    height: 50,
  },
  input_select: {
    width: "100%",
    fontSize: 16,
    background: `url(${Ic_change_option}) 97.5% / 10px no-repeat #f7f7f7`,
    appearance: "none",
    border: "none",
    borderRadius: 5,
    paddingLeft: 8,
    marginTop: "10px 0",
    height: 50,
  },
  input_area: {
    width: "calc(100% - 10px)",
    fontSize: 16,
    backgroundColor: "#f7f7f7",
    border: "none",
    paddingLeft: 10,
    paddingTop: 10,
    marginTop: 10,
    marginBottom: 10,
    height: 200,
    resize: "none",
    fontFamily: "arial",
  },
  container_contact: {
    paddingLeft: 200,
    paddingRight: 200,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  container_contact_main: {
    width: "100%",
    height: "75vh",
    background: "#2153a4",
    paddingTop: 50,
    marginBottom: 300,
    [theme.breakpoints.down("md")]: {
      height: "100%",
      marginBottom: 40,
      paddingBottom: 50,
    },
  },
  container_alliances_main: {
    width: "100%",
    height: "50vh",
    background: "#2153a4",
    paddingTop: 50,
    marginBottom: 130,
    [theme.breakpoints.down("md")]: {
      height: "100%",
      marginBottom: 40,
      paddingBottom: 50,
    },
  },
  button_form: {
    background: "#F36C22",
    color: "#fff",
    cursor: "pointer",
    border: "none",
    fontSize: 24,
    height: 60,
    width: 200,
    textTransform: "uppercase",
    "&:active": {
      transform: "scale(0.98)",
    },
  },
  login_option1: {
    textTransform: "uppercase",
    fontSize: 20,
    fontWeight: 600,
    color: "#000",
    margin: 0,
    cursor: "pointer",
    userSelect: "none",
  },
  login_option2: {
    textTransform: "uppercase",
    fontSize: 20,
    fontWeight: 600,
    color: "#c6c6c6",
    margin: 0,
    cursor: "pointer",
    userSelect: "none",
  },
  title_store1: {
    color: "#F36C22",
    fontWeight: 700,
    fontSize: 50,
    textTransform: "uppercase",
    cursor: "pointer",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      margin: "10px 20px",
      // width:'100%',
    },
  },
  title_store2: {
    color: "#D8D8D8",
    fontWeight: 700,
    fontSize: 50,
    textTransform: "uppercase",
    cursor: "pointer",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      margin: "10px 20px",
      // width:'100%',
    },
  },
  search_store1: {
    fontSize: 23,
    fontWeight: 600,
    textTransform: "uppercase",
    cursor: "pointer",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  search_store2: {
    fontSize: 23,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#D8D8D8",
    cursor: "pointer",
    userSelect: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  camera: {
    background: "#2153a4",
    width: 45,
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    position: "absolute",
    right: 10,
    bottom: 10,
    cursor: "pointer",
  },
  container_table: {
    marginTop: 50,
    marginBottom: 50,
    padding: "0 100px",
    [theme.breakpoints.down("lg")]: {
      padding: "0 40px",
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  table_desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  table_mobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "block",
    },
  },
  container_payments: {
    display: "flex",
    marginBottom: 25,
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  container_payments2: {
    display: "flex",
    marginBottom: 25,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  title_payment: {
    maxWidth: 300,
    fontWeight: 700,
    fontSize: 45,
    paddingTop: 100,
    marginRight: 40,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      margin: 0,
    },
  },
  container_method_payments1: {
    borderBottom: "1px solid #c0c0c0",
    borderTop: "1px solid #c0c0c0",
    width: "36vw",
    height: 500,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: 200,
      justifyContent: "center",
    },
  },
  container_method_payments2: {
    width: "28vw",
    height: 500,
    border: "1px solid #c0c0c0",
    display: "flex",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "calc(50vw - 5px)",
      borderRight: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      border: "none",
      marginTop: 5,
      height: 470,
    },
  },
  container_method_payments3: {
    backgroundColor: "#c0c0c0",
    borderBottom: "1px solid #c0c0c0",
    borderTop: "1px solid #c0c0c0",
    width: "36vw",
    height: 500,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      width: "100vw",
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      width: "100vw",
      height: 300,
    },
  },
  container_method_payments4: {
    backgroundColor: "#c0c0c0",
    width: "28vw",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      width: "100vw",
      height: 300,
    },
  },
  container_method_payments8: {
    backgroundColor: "#c0c0c0",
    width: "28vw",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      width: "100vw",
      height: 300,
    },
  },
  container_method_payments5: {
    backgroundColor: "#2153a4",
    width: "20%",
    minHeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      width: "100vw",
      height: 300,
    },
  },
  container_method_payments6: {
    borderBottom: "1px solid #c0c0c0",
    borderTop: "1px solid #c0c0c0",
    width: "80%",
    minHeight: 500,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
      width: "100vw",
      height: "100%",
      justifyContent: "center",
    },
  },
  container_method_payments9: {
    backgroundColor: "#c0c0c0",
    width: "28vw",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      width: "100vw",
      height: 300,
      justifyContent: "flex-start",
      paddingLeft: 15,
    },
  },
  container_method_payments10: {
    borderBottom: "1px solid #c0c0c0",
    borderTop: "1px solid #c0c0c0",
    width: "80%",
    minHeight: 500,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      width: "100vw",
      height: "100%",
      justifyContent: "center",
    },
  },
  content_method_form: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content_method_form2: {
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  container_method_payments7: {
    width: "80%",
    minHeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderBottom: "1px solid #c0c0c0",
    borderTop: "1px solid #c0c0c0",
    [theme.breakpoints.down("md")]: {
      marginTop: 5,
      height: "auto",
      justifyContent: "flex-start",
      width: "100%",
    },
  },
  number_button: {
    fontWeight: 600,
    marginLeft: 10,
    marginRight: 10,
    // marginTop: -30,
    fontSize: 20,
    cursor: "pointer",
    userSelect: "none",
  },
  button_modal: {
    border: "none",
    borderRadius: 9,
    width: 100,
    height: 40,
    boxShadow: "0 6px 10px 0 rgba(6,24,77,0.09)",
    cursor: "pointer",
    fontSize: 15,
    backgroundColor: "#fff",
  },
  promotion_container: {
    width: "98%",
    height: "100%",
    paddingLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingLeft: 0,
    },
  },
  banner_container: {
    width: "100%",
    height: "90vh",
    background: "#2153a4",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      paddingTop: 50,
      height: "100%",
      // background: "#fff",
    },
  },
  main_picture: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      height: 400,
    },
  },
  container_banner_paginate: {
    background: "#2153a4",
    marginTop: -70,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      paddingBottom: 20,
      paddingTop: 20,
      background: "#fff",
    },
  },
  sport_text_images_default: {
    display: "none",
  },
  sport_text_images: {
    width: "100%",
    position: "absolute",
    top: "35%",
    animation: "$sport_text_images .3s linear both",
  },
  "@keyframes sport_text_images": {
    "0%": {
      opacity: 0,
      transform: "translateY(100px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  count_total_product: {
    background: "#2ed37c",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    height: 28,
    width: 28,
    color: "#fff",
    fontSize: 14,
    fontWeight: 600,
    bottom: -10,
    right: 35,
  },
  promotion_balls: {
    width: 330,
    [theme.breakpoints.down("md")]: {
      width: 220,
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      marginTop: -20,
    },
  },
  hashtag_text: {
    fontSize: 50,
    fontWeight: 500,
    margin: 0,
    color: "#fff",
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    },
  },
  container_menu_store: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0",
    [theme.breakpoints.down("xs")]: {
      overflowY: "auto",
      justifyContent: "flex-start",
    },
  },
  container_submenu_store: {
    borderTop: "1px solid #e3e3e3",
    borderBottom: "1px solid #e3e3e3",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      margin: 0,
      justifyContent: "space-between",
      width: "100%",
      marginTop: 40,
    },
  },
  contianer_submenu_store_option1: {
    display: "flex",
    justifyContent: "space-between",
    // borderRight: "1px solid #e3e3e3",
    [theme.breakpoints.down("xs")]: {
      // borderBottom: "1px solid #e3e3e3",
      borderRight: 0,
      width: "100%",
    },
  },
  us_banner_title1: {
    fontSize: 70,
    color: "#fff",
    marginBottom: 20,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: 45,
    },
  },
  container_us_info1: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  container_us_info2: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  container_us_info3: {
    display: "flex",
    alignItems: "center",
    textAlign: "end",
    maxWidth: 800,
    marginTop: -50,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      flexWrap: "wrap",
      alignItems: "flex-start",
      textAlign: "start",
      flexDirection: "column-reverse",
    },
  },
  us_text_mission: {
    fontSize: 20,
    marginTop: 120,
    marginLeft: 10,
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  us_text_vision: {
    fontSize: 20,
    marginRight: 10,
    marginTop: 60,
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  contianer_sports: {
    marginTop: 130,
    marginBottom: 130,
    [theme.breakpoints.down("xs")]: {
      marginTop: 80,
      marginBottom: 80,
    },
  },
  container_card_ball: {
    textAlign: "center",
    // marginBottom: 20,
    // minHeight: 150,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 70,
    },
  },
  container_cardball_top: {
    display: "flex",
    overflow: "auto",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      // justifyContent: "flex-start",
      // flexWrap: "wrap",
      display: "none",
    },
  },
  container_cardball_top2: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  container_promotion_ball1: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      paddingTop: 30,
    },
  },
  container_promotion_ball1_info: {
    lineHeight: 1.1,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      paddingBottom: 60,
    },
  },
  container_promotion_ball2: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  promotion_balls2: {
    width: 330,
    display: "none",
    [theme.breakpoints.down("md")]: {
      width: 220,
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  promotion_balls3: {
    width: 330,
    [theme.breakpoints.down("md")]: {
      width: 220,
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      // display:'none',
    },
  },
  container_moreinfo_content: {
    backgroundColor: "#f36c22",
    width: 400,
    margin: 20,
    paddingLeft: 10,
    marginLeft: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 10,
      margin: 0,
    },
  },
  modal_login: {
    background: "#fff",
    height: 500,
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "90%",
    },
  },
  options_payments_text: {
    fontSize: 35,
    padding: "10px 0",
    margin: 0,
    userSelect: "none",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  margin_input: {
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: { marginLeft: 0 },
  },
  margin_input2: {
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  title_payments: {
    color: "#000",
    fontWeight: 700,
    fontSize: 60,
    [theme.breakpoints.down("xs")]: { fontSize: 40 },
  },
  container_panel_menu_desktop: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  container_panel_menu_mobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  container_panel_menu_options: {
    marginTop: 0,
    marginLeft: 250,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  my_records_title: {
    color: "#fff",
    margin: 0,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  info_text_record: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      margin: 0,
      paddingTop: 10,
    },
  },
  space_records: {
    [theme.breakpoints.down("xs")]: {
      height: 100,
      // marginBottom:10s0,
    },
  },
  container_contact_info: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title_info_contact: {
    fontSize: 24,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
      marginBottom: -20,
    },
  },
  title_info_contact2: {
    fontSize: 24,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  title_info_contact3: {
    fontSize: 24,
    fontWeight: 600,
    margin: 0,
  },
  container_footer: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  container_alliances_card: {
    background: "#f6f6f6",
    width: "95%",
    height: 260,
    borderRadius: 30,
    display: "flex",
    margin: "50px 0",
    [theme.breakpoints.down("xs")]: {
      height: 130,
      margin: "20px 0",
    },
  },
  content_alliances_card_image: {
    background: "#d8d8d8",
    height: "100%",
    minWidth: 260,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    objectFit: "scale-down",
    [theme.breakpoints.down("xs")]: {
      minWidth: 130,
    },
  },
  title_card_alliances: {
    margin: 0,
    fontWeight: 600,
    fontSize: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  title_error: {
    margin: 0,
    fontWeight: 700,
    fontSize: 50,
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
  subtitle_error: {
    margin: 0,
    fontSize: 22,
    color: "#fff",
    maxWidth: 400,
    paddingTop: 10,
  },
}));
