import {useState, useCallback, useEffect}   from 'react';
import {Container, Modal}   from '@material-ui/core';
import Cropper              from 'react-easy-crop';

import getCroppedImg from 'utils';
// Styles
import {useStyles} from 'style';

const CropImage = ({image, setImage}) => {
    const classes= useStyles();

    const [crop, setCrop]                           = useState({ x: 0, y: 0 })
    const [rotation, setRotation]                   = useState(0)
    const [zoom, setZoom]                           = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [auximage, setAuxImage]                   = useState(image)
    const [open, setOpen]                           = useState(false)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(auximage,croppedAreaPixels,rotation)
            setZoom(1)
            closeTimeAction(croppedImage)

        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const closeTimeAction = (croppedImage) =>{
        setOpen(false)
        setImage(croppedImage)
        setAuxImage(croppedImage)
    }

    const dontCloseOutside = () =>{}

    useEffect(() =>{
        if(image){
            if(typeof image==='object'){     
                const url = window.URL.createObjectURL(image)
                setAuxImage(url)
            } else{
                setAuxImage(image)
            }
            setOpen(true)
        }
    },[image])
    return (
        <div>
            <Modal
                style={{display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'rgba(0,0,0,0.30)'}}
                open={open}
                onClose={() => dontCloseOutside()}>
                    <Container maxWidth="sm">
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <div>
                                <p style={{color:'#fff', fontFamily:'semi', fontSize:18, marginBottom:5}}>{'Ajusta tu imgen'}</p>
                                <div style={{textAlign:'center'}}>
                                    <div style={{width:500, height:500, position:'relative', objectFit:'scale-down'}}>
                                        <Cropper
                                            image={auximage}
                                            crop={crop}
                                            rotation={rotation}
                                            zoom={zoom}
                                            aspect={1 / 1}
                                            onCropChange={setCrop}
                                            onRotationChange={setRotation}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                            />
                                    </div>
                                    <div style={{marginTop:20}}>
                                        <button onClick={showCroppedImage} className={classes.button_modal}>{'Listo'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Modal>
        </div>
    );
};

export default CropImage;