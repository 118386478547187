import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBVHf24qr4xGdKWrU29CY8fLwrv7QknPAk",
    authDomain: "gaser-5c45b.firebaseapp.com",
    projectId: "gaser-5c45b",
    storageBucket: "gaser-5c45b.appspot.com",
    messagingSenderId: "604568137351",
    appId: "1:604568137351:web:f559f808bc83c2bfded222",
    measurementId: "G-TYT1RHFL6Z"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);