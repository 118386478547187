import Lottie from 'react-lottie';

import {Logo_big, Gaser} from '../../values';

const LoadingPage = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Gaser,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div style={{background:'#2153a3', width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {/* <img alt='' src={Logo_big} style={{width:350}} /> */}
            <Lottie options={defaultOptions} height={400} width={400} isClickToPauseDisabled={true} /> 
        </div>
    );
};

export default LoadingPage;