import { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// Redux
import { changeFlagPath } from "redux/actions/Store";
// Components
import MenuDrawer from "./MenuDrawer";
import Login from "./Login";
// Values
import { Logo_small, Ic_cart, Ic_user, paths } from "values";
// Styles
import { useStyles } from "style";

const Title = ({ text, path, pathMenu, changePage, height = 65, width = 90, heightBar, setHeight, sizeClose = 0 }) => {
  const classes = useStyles();
  const standBy = () => {};
  return (
    <div
      style={{ position: "relative", height: 70, marginBottom: -30 }}
      onMouseEnter={() => setHeight(height)}
      onMouseLeave={() => (path === pathMenu ? standBy() : setHeight(sizeClose))}
    >
      <div
        onClick={() => changePage(pathMenu)}
        className="line"
        style={{ width: width, height: 65, transform: `scaleY(${heightBar === 65 ? 1 : 0})` }}
      />

      <p
        onClick={() => changePage(pathMenu)}
        className={classes.path_menu}
        style={{
          fontFamily: path === pathMenu ? "m_medium" : "m_light",
        }}
      >
        {text}
      </p>
    </div>
  );
};

const Menu = ({ token, store, changeFlagPath }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [path, setPath] = useState(location.pathname.slice(1, location.pathname.length));
  const [heightBar1, setHeight1] = useState(path === paths.usPath ? 65 : 0);
  const [heightBar2, setHeight2] = useState(path === paths.soccerPath ? 65 : 0);
  const [heightBar3, setHeight3] = useState(path === paths.basketPath ? 65 : 0);
  const [heightBar4, setHeight4] = useState(path === paths.voleyPath ? 65 : 0);
  const [heightBar5, setHeight5] = useState(path === paths.contactPath ? 65 : 0);
  const [heightBar6, setHeight6] = useState(path === paths.contactPath ? 65 : 0);

  const [open, setOpen] = useState(false);

  const changePage = (path) => {
    history.push(`/${path.toLowerCase()}`);
    setPath(path);
    if (path === "") {
      setHeight1(0);
      setHeight2(0);
      setHeight3(0);
      setHeight4(0);
      setHeight5(0);
      setHeight6(0);
    }
    if (path === paths.usPath) {
      setHeight1(65);
      setHeight2(0);
      setHeight3(0);
      setHeight4(0);
      setHeight5(0);
      setHeight6(0);
    }
    if (path === paths.soccerPath) {
      setHeight1(0);
      setHeight2(65);
      setHeight3(0);
      setHeight4(0);
      setHeight5(0);
      setHeight6(0);
      changeFlagPath(false);
    }
    if (path === paths.basketPath) {
      setHeight1(0);
      setHeight2(0);
      setHeight3(65);
      setHeight4(0);
      setHeight5(0);
      setHeight6(0);
      changeFlagPath(false);
    }
    if (path === paths.voleyPath) {
      setHeight1(0);
      setHeight2(0);
      setHeight3(0);
      setHeight4(65);
      setHeight5(0);
      setHeight6(0);
      changeFlagPath(false);
    }
    if (path === paths.contactPath) {
      setHeight1(0);
      setHeight2(0);
      setHeight3(0);
      setHeight4(0);
      setHeight5(65);
      setHeight6(0);
    }
    if (path === paths.cartPath) {
      setHeight1(0);
      setHeight2(0);
      setHeight3(0);
      setHeight4(0);
      setHeight5(0);
      setHeight6(0);
    }
    if (path === paths.alliancesPath) {
      setHeight1(0);
      setHeight2(0);
      setHeight3(0);
      setHeight4(0);
      setHeight5(0);
      setHeight6(65);
    }
  };

  const loginUser = () => {
    if (token) {
      setHeight1(0);
      setHeight2(0);
      setHeight3(0);
      setHeight4(0);
      setHeight5(0);
      setHeight6(0);
      setPath("panel");
      history.push(`/panel`);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    changePage(location.pathname.slice(1, location.pathname.length));
  }, [location.pathname]);
  return (
    <div>
      <Login open={open} setOpen={setOpen} />
      <div className={classes.container_menu_drawer}>
        <MenuDrawer menuChange={changePage} path={path} login={loginUser} />
      </div>
      <div className={classes.container_menu}>
        <Container maxWidth="lg">
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <img
              alt=""
              loading="lazy"
              draggable="false"
              src={Logo_small}
              style={{ cursor: "pointer", width: "100%", maxWidth: 140 }}
              onClick={() => changePage("")}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Title
                text={paths.usTitle}
                pathMenu={paths.usPath}
                path={path}
                heightBar={heightBar1}
                setHeight={setHeight1}
                changePage={changePage}
              />

              <Title
                text={paths.soccerTitle}
                pathMenu={paths.soccerPath}
                path={path}
                heightBar={heightBar2}
                setHeight={setHeight2}
                changePage={changePage}
              />

              <Title
                text={paths.basketTitle}
                pathMenu={paths.basketPath}
                path={path}
                heightBar={heightBar3}
                setHeight={setHeight3}
                width={110}
                changePage={changePage}
              />

              <Title
                text={paths.voleyTitle}
                pathMenu={paths.voleyPath}
                path={path}
                heightBar={heightBar4}
                setHeight={setHeight4}
                changePage={changePage}
              />

              <Title
                text={paths.alliancesTitle}
                pathMenu={paths.alliancesPath}
                path={path}
                heightBar={heightBar6}
                setHeight={setHeight6}
                changePage={changePage}
              />

              <Title
                text={paths.contactTitle}
                pathMenu={paths.contactPath}
                path={path}
                heightBar={heightBar5}
                setHeight={setHeight5}
                changePage={changePage}
              />
            </div>

            {/* <div style={{ display: "flex", position: "relative" }}>
              <div className={classes.button_card} onClick={() => changePage(paths.cartPath)}>
                <img alt="" loading="lazy" draggable="false" src={Ic_cart} style={{ width: 25 }} />
              </div>
              {store.length !== 0 && <div className={classes.count_total_product}>{store.length}</div>}
              <img
                alt=""
                onClick={() => loginUser()}
                loading="lazy"
                src={Ic_user}
                draggable="false"
                style={{ width: 25, marginLeft: 20, cursor: "pointer" }}
              />
            </div> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.Panel.token,
  store: state.Store.data,
});

export default connect(mapStateToProps, { changeFlagPath })(Menu);
