import PayPalExpressButton from 'react-paypal-express-checkout';
import { toast }    from 'react-toastify';
import {Container}  from '@material-ui/core';

const PayPalButton = ({total, payment}) => {
    // const env = 'sandbox'
    const env = 'production';
    const currency = 'MXN';
    const client = {
        sandbox: 'AU9am37lXuiRApBrK9G9sB_1iSJzYHjfxj49RehDn-EkF4btWf7yoh396utn3N4RCMN-CZNJ-rdYx1vN',
        production: 'AWxKdSxuxblbjp1opBQXTBPlDsbzIN86RmE0-XVsh4BwwqYvq7jOnP__GY1EC5mhqKwffDSptBXTOPFP'
    }

    const onSuccess = () => {
        console.log("Pago exitoso");
        // toast.success('Pago exitoso, se te enviara un correo.')
        payment()
    }
    const onCancel = () => {
        console.log("Pago cancelado");
        toast.error('El pago fue cancelado');
    }
    const onError = () => {
        console.log("Ocurrio un error");
        toast.warn('Ocurrió un error al procesar tu pago. Por favor intentalo de nuevo más tarde');
    }

    return (
        <div style={{textAlign:'center', paddingBottom:50}}>
            <Container maxWidth="xl">
                <PayPalExpressButton
                    // className = { classes.button }
                    env={env}
                    client={client}
                    currency={currency}
                    total={total}
                    onError={onError}
                    onSuccess={onSuccess}
                    onCancel={onCancel} 
                    style = {{ 
                        size: 'medium',
                        height: 50,
                        shape: 'pill',
                        label: 'paypal',
                        tagline: 'false',
                        color: 'silver',
                        layout: 'horizontal',
                        fundingicons: true,
                    }} />
            </Container>
        </div>
    );
};

export default PayPalButton;