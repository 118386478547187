import {useEffect, useState}   from 'react';
import {Container}  from '@material-ui/core';
import {connect}    from 'react-redux';
import {toast}      from 'react-toastify';
// Redux
import {updateUser} from 'redux/actions/PanelAdmin';
// Components
import Input    from './Input';
import Button   from './Button';
// Values
import {toastText} from 'values';
// Api
import {updatePersonalData} from 'utils/users';

const Perfil = ({data, updateUser, trigger, setTrigger}) => {
    const [blockData, setBlockData] = useState('disabled')
    const [name, setName]           = useState('')
    const [lastName, setLastName]   = useState('')
    const [phone, setPhone]         = useState('')
    const [loading, setLoading]     = useState(false)

    const updateData = async() =>{
        setLoading(true)
        const res = await updatePersonalData(data.id, name, lastName, phone)
        if(res){
            setBlockData('disabled')
            setLoading(false)
            toast.success(toastText.update)
            localStorage.setItem('gaser-name', name)
            localStorage.setItem('gaser-lastname', lastName)
            updateUser(name, lastName)
            setTrigger(()=>(trigger+1))
        } else{
            toast.warn(toastText.warning)
        }
        setLoading(false)
    }

    useEffect(()=>{
        setName(data.name)
        setLastName(data.lastName)
        setPhone(data.phone)
    },[data])
    return (
        <div style={{boxShadow:'0px 0px 10px -4px rgba(0,0,0,0.35)', borderRadius:15}}>
            <Container>
                <p style={{fontWeight:600, fontSize:20, marginBottom:25, paddingTop:15, fontFamily:'m_semi_bold'}}>{'Personales'}</p>
                <div style={{padding:'0 10px'}}>
                    <Input title={'Nombre'} value={name} setValue={setName} blockData={blockData} placeholder={''} />
                    <Input title={'Apellido'} value={lastName} setValue={setLastName} blockData={blockData}placeholder={''} />
                    <Input title={'Teléfono'} value={phone} setValue={setPhone} blockData={blockData} placeholder={''} />
                </div>
                <div style={{height:20}} />
                <Button blockData={blockData} 
                    setBlockData={()=>setBlockData('')}
                    onClick={updateData} loading={loading} />
                <div style={{height:20}} />
            </Container>
        </div>
    );
};

const mapStateToProps = state =>({
    data:       state.Panel.data
})

export default connect(mapStateToProps, {updateUser})(Perfil)