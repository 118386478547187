import {urlApi, urlPath, get, } from '../api';

const url = urlApi + urlPath.webProducts

export const getData = async(token='', extra='') =>{
    return await get(url, token, extra)
} 

export const searchCoupon = async(token='', extra='') =>{
    const urlAux = urlApi + urlPath.webCoupon
    return await get(urlAux, token, extra)
} 
