import {urlApi, urlPath, post, } from '../api';
import {emailformat} from '../index';

const url = urlApi + urlPath.weSubscribers

export const postData = async(email, token) =>{
    const obj = {
        email
    }
    return await post(obj, url, token)
}

export const validateData = (email) =>{
    if(!email.match(emailformat)){
        return {flag:false, message:'Parece que algo anda mal con el correo.'}
    }
    return {flag:true, message:''}
}