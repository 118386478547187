import { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uniqid from "uniqid";

// Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./firebase";
// Redux
import { refreshToken, getUser, saveToken, getSales } from "./redux/actions/PanelAdmin";
import { getBanners } from "./redux/actions/Banners";
import { getProducts } from "./redux/actions/Products";
import { getAlliances } from "./redux/actions/Alliances";
import { getSetup } from "./redux/actions/Setup";
import { getDealers } from "./redux/actions/Dealers";

// Styles
import "./App.css";
import "./fonts/font.css";
// Components
import LoadingPage from "./components/global/LoadingPage";
import ScrollToTop from "./components/global/ScrollToTop";
import Panel from "./components/panel/Panel";
import Menu from "./components/global/Menu";
// import Home         from './components/home/Home';
import Us from "./components/us/Us";
import Contact from "./components/contact/Contact";
import Store from "./components/store/Store";
import Cart from "./components/cart/Cart";
import Alliances from "./components/alliances/Alliances";
import Error404 from "./components/global/Error404";
import IndividualProduct from "./components/store/IndividualProduct";
import PrivacyPolicies from "./components/privacyPolicies/PrivacyPolicies";
import ReturnPolicies from "components/returnPolicies/ReturnPolicies";

// Api
import { getData as getUserInfo, getMySales } from "utils/users";
import { getData as getBannersInfo } from "utils/banner";
import { getData as getProductsInfo } from "utils/products";
import { getData as getAlliancesInfo } from "utils/alliances";
import { getData as getSetupInfo } from "utils/setup";
import { getData as getDealersInfo } from "utils/dealers";

// Value
import { paths } from "values";

// const Menu      = lazy(() => import('./components/global/Menu'));
const Home = lazy(() => import("./components/home/Home"));
// const Footer    = lazy(() => import('components/global/Footer'));
// const Us        = lazy(() => import('./components/us/Us'));
// const Contact   = lazy(() => import('./components/contact/Contact'));
// const Store     = lazy(() => import('./components/store/Store'));
// const Cart      = lazy(() => import('./components/cart/Cart'));

const Default = () => {
  return <div></div>;
};

function App({
  refreshToken,
  getBanners,
  getUser,
  getProducts,
  getAlliances,
  saveToken,
  getSales,
  getSetup,
  getDealers,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingBanners, setLoadingBanners] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const [auxBanner, setAuxBanner] = useState([]);
  const [auxSoccer, setAuxSoccer] = useState([]);
  const [auxBasket, setAuxBasket] = useState([]);
  const [auxVolley, setAuxVolley] = useState([]);

  const getInfo = async () => {
    const resBanners = await getBannersInfo();
    getBanners(resBanners.data.data);
    setAuxBanner(resBanners.data.data);
  };

  const loadImages = () => {
    setTimeout(() => setLoadingBanners(true), 2000);
  };

  const getInfoUser = async (uid) => {
    const resUser = await getUserInfo("", `/${uid}`);
    localStorage.setItem("gaser-picture", resUser.data.data.picture);
    getUser(resUser.data.data);
  };

  const getInfoProducts = async () => {
    const resProducts = await getProductsInfo();
    try {
      setAuxSoccer(resProducts.data.data.soccer);
      setAuxBasket(resProducts.data.data.basket);
      setAuxVolley(resProducts.data.data.volley);
      getProducts(
        resProducts.data.data.soccer,
        resProducts.data.data.basket,
        resProducts.data.data.volley,
        resProducts.data.data.top
      );
    } catch (error) {}
  };

  const getAllSales = async (uid) => {
    const resSales = await getMySales("", `/${uid}`);
    getSales(resSales.data.data);
  };

  const getAlliancesData = async () => {
    const resSales = await getAlliancesInfo();
    getAlliances(resSales.data.data);
  };

  const getAllSetup = async () => {
    const resSetup = await getSetupInfo();
    getSetup(resSetup.data.data);
  };

  const getAllDealers = async () => {
    const resDealers = await getDealersInfo();
    // console.log("r: ", resDealers.data.data);
    getDealers(resDealers.data.data);
  };

  const verifyUser = async () => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // console.log("Admin auth");
        await getInfoUser(user.uid);
        await getAllSales(user.uid);
        await auth.currentUser.getIdToken(true).then((token) => {
          // console.log('Token: ', token);
          localStorage.setItem("gaser-key", token);
          refreshToken(token);
        });
        setLoading(true);
      } else {
        setLoading(false);
        window.localStorage.removeItem("gaser-name");
        window.localStorage.removeItem("gaser-lastname");
        window.localStorage.removeItem("gaser-picture");
        window.localStorage.removeItem("gaser-rol");
        window.localStorage.removeItem("gaser-key");
        window.localStorage.removeItem("gaser-email");
        saveToken("", "", "", "", "", "");
      }
    });
  };
  useEffect(() => {
    verifyUser();
    getInfoProducts();
    getInfo();
    getAlliancesData();
    getAllSetup();
    getAllDealers();
  }, []);
  return (
    <Router>
      <ToastContainer />
      <ScrollToTop />

      <Suspense fallback={<LoadingPage />}>
        {loadingBanners ? (
          <>
            <Menu />
            <>
              <Route exact path="/" component={Home} />
              <Route exact path={`/${paths.usPath}`} component={Us} />
              <Route exact path={`/${paths.contactPath}`} component={Contact} />
              <Route exact path={`/${paths.soccerPath}/:id?`} component={Store} />
              <Route exact path={`/${paths.voleyPath}/:id?`} component={Store} />
              <Route exact path={`/${paths.basketPath}/:id?`} component={Store} />
              {/* <Route exact path={`/${paths.cartPath}`} component={Cart} /> */}
              <Route exact path={`/${paths.alliancesPath}`} component={Alliances} />
              <Route exact path={"/balon/:id?"} component={IndividualProduct} />
              <Route exact path={"/politicas-de-privacidad"} component={PrivacyPolicies} />
              <Route exact path={"/politicas-de-devoluciones"} component={ReturnPolicies} />
              {/* {loading ? (
                loadingBanners && <Route exact path="/panel/:id?" component={Panel} />
              ) : (
                <Route path="*">
                  <Default />
                </Route>
              )} */}

              {/* <Route path='*' component={Error404} /> */}
            </>
          </>
        ) : (
          <>
            <LoadingPage />
            {auxBanner &&
              auxBanner.map((i, j) => (
                <div key={uniqid()}>
                  <img alt="" src={i.image} onLoad={loadImages} style={{ display: "none" }} />
                  <img alt="" src={i.bigImage} onLoad={loadImages} style={{ display: "none" }} />
                  <img alt="" src={i.imageBack} onLoad={loadImages} style={{ display: "none" }} />
                </div>
              ))}
            {auxSoccer &&
              auxSoccer.map((i) => (
                <div key={uniqid()}>
                  {i.models &&
                    i.models.map((j) => <img key={uniqid()} alt="" src={j.picture} style={{ display: "none" }} />)}
                </div>
              ))}
            {auxBasket &&
              auxBasket.map((i) => (
                <div key={uniqid()}>
                  {i.models &&
                    i.models.map((j) => <img key={uniqid()} alt="" src={j.picture} style={{ display: "none" }} />)}
                </div>
              ))}
            {auxVolley &&
              auxVolley.map((i) => (
                <div key={uniqid()}>
                  {i.models &&
                    i.models.map((j) => <img key={uniqid()} alt="" src={j.picture} style={{ display: "none" }} />)}
                </div>
              ))}
          </>
        )}
      </Suspense>
    </Router>
  );
}

export default connect(null, {
  refreshToken,
  getBanners,
  getUser,
  getProducts,
  saveToken,
  getSales,
  getAlliances,
  getSetup,
  getDealers,
})(App);
