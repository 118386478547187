import { reduxState } from "values";

export const getDealers = (data) => {
  return (dispatch) => {
    dispatch({
      type: reduxState.get_dealers,
      data,
    });
  };
};
