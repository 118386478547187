import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import Alliances from "./reducers/Alliances";
import Banners from "./reducers/Banners";
import Dealers from "./reducers/Dealers";
import Panel from "./reducers/PanelAdmin";
import Products from "./reducers/Products";
import Setup from "./reducers/Setup";
import Store from "./reducers/Store";

const reducers = combineReducers({
  Alliances,
  Banners,
  Dealers,
  Panel,
  Products,
  Setup,
  Store,
});

const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunkMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )
);

export default store;
