
import Hashtag  from '../global/Hashtag';
import Location from './Location';
import Banner   from './Banner';
import Footer   from '../global/Footer'

const Contact = () => {
    return (
        <div>
            <Banner />
            <Location />
            <Hashtag />
            <Footer />
        </div>
    );
};

export default Contact;