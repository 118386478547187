import {reduxState, stateData}  from 'values';

const initialState = {
    data:       [],
    status:     stateData.state1,
    dataEdit:   [],
    records:    1,
    page:       1
};

const reducer = (state = initialState, action) =>{
    if(action.type === reduxState.get_banners){
        return{
            ...state,
            data:   action.data,
            status: stateData.state2
        }
    }
    return state
}
export default reducer;