import { reduxState } from 'values';

export const getSetup = (data) => {
    return (dispatch) => {
        dispatch({
            type: reduxState.get_setup,
            data,
        })
    }
}
