import { useState, useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";

// Redux
import { changeFlagPath } from "redux/actions/Store";
// Components
import CardBall from "./CardBall";
import Hashtag from "../global/Hashtag";
import Footer from "../global/Footer";
import Modal from "./IndividualProduct";
import NewIndividualProducts from "./NewIndividualProducts";
import Maps from "../global/Maps";

// Style
import { useStyles } from "style";
// Values
import { paths } from "values";

const SearchText = ({ text, setSearch, search, value }) => {
  const classes = useStyles();
  return (
    <p
      onClick={() => setSearch(value)}
      style={{ fontFamily: "m_extra_bold" }}
      className={search === value ? classes.search_store1 : classes.search_store2}
    >
      {text}
    </p>
  );
};

const Store = ({ soccer, volley, basket, flagPath, changeFlagPath }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const [scroll, setScroll] = useState(0);
  const [path, setPath] = useState(location.pathname.slice(1, location.pathname.length));

  const [search1, setSearch1] = useState(false);
  const [search2, setSearch2] = useState(true);

  const [data, setData] = useState([]);
  const [auxData, setAuxData] = useState({});
  const [open, setOpen] = useState(false);

  const openModal = (data) => {
    history.push(`/${path.toLowerCase()}/${data.id}`);
    setOpen(true);
    changeFlagPath(true);
    setAuxData(data);
  };

  const closeModal = () => {
    let count = path.toLowerCase().indexOf(`/${id}`);
    let newPath = "";
    for (let i = 0; i < count; i++) {
      newPath += path[i];
    }

    if (newPath) {
      setPath(newPath.toLowerCase());
      history.push(`/${newPath.toLowerCase()}`);
    } else {
      history.push(`/${path.toLowerCase()}`);
    }

    setOpen(false);
    setAuxData({});
  };

  const changePage = (path) => {
    history.push(`/${path.toLowerCase()}`);
  };

  const mapData = (dataSearch) => {
    let aux = {};

    dataSearch.forEach((i) => {
      if (i.id.toUpperCase() === id.toUpperCase()) {
        aux = i;
      }
    });

    if (aux.length !== 0) {
      setOpen(true);
      changeFlagPath(true);
      setAuxData(aux);
    }
  };

  const filterData = (data) => {
    let info = [];
    if (search1) {
      info = data.filter((i) => i.line === "TRAINIG");
    } else {
      info = data.filter((i) => i.line === "PREMIUM");
    }

    if (search2) {
      info = info.sort((i, j) => j.priceDefault - i.priceDefault);
    } else {
      info = info.sort((i, j) => i.priceDefault - j.priceDefault);
    }

    if (id !== undefined) {
      mapData(data);
    }
    setData(info);
  };

  const onScroll = () => {
    // const scrollY = window?.scrollX;
    // const scrollTop = document.getElementById("menu_balls").scrollTop;
    const scrollTop = document.getElementById("menu_balls").scrollLeft;
    // console.log("scrollTop: ", scrollTop);

    // 0 - 108 - 243
  };

  useEffect(() => {
    if (path === paths.soccerPath) {
      filterData(soccer);
      document.getElementById("menu_balls").scrollLeft = 0;
    }
    if (path === paths.basketPath) {
      filterData(basket);
      document.getElementById("menu_balls").scrollLeft = 108;
    }
    if (path === paths.voleyPath) {
      filterData(volley);
      document.getElementById("menu_balls").scrollLeft = 243;
    }

    if (path === paths.soccerPath + `/${id}`) {
      filterData(soccer);
    }
    if (path === paths.basketPath + `/${id}`) {
      filterData(basket);
    }
    if (path === paths.voleyPath + `/${id}`) {
      filterData(volley);
    }
  }, [path, search1, search2]);

  useEffect(() => {
    // console.log('UseEffect 2');
    // console.log('flagPath: ', flagPath);
    // console.log('open: ', open);
    if (flagPath === false && open === true) {
      setOpen(false);
      setAuxData({});
    }
  }, [flagPath, open]);
  return (
    <>
      <NewIndividualProducts open={open} closeModal={closeModal} data={auxData} />
      {open === false ? (
        <>
          <Container>
            <div id="menu_balls" onScroll={onScroll} className={classes.container_menu_store}>
              <p
                onClick={() => changePage(paths.soccerPath)}
                style={{ fontFamily: "m_extra_bold" }}
                className={path === paths.soccerPath ? classes.title_store1 : classes.title_store2}
              >
                {paths.soccerTitle}
              </p>
              <div style={{ width: 30 }} />
              <p
                onClick={() => changePage(paths.basketPath)}
                style={{ fontFamily: "m_extra_bold" }}
                className={path === paths.basketPath ? classes.title_store1 : classes.title_store2}
              >
                {paths.basketTitle}
              </p>
              <div style={{ width: 30 }} />
              <p
                onClick={() => changePage(paths.voleyPath)}
                style={{ fontFamily: "m_extra_bold" }}
                className={path === paths.voleyPath ? classes.title_store1 : classes.title_store2}
              >
                {paths.voleyTitle}
              </p>
            </div>
          </Container>
          <div className={classes.container_submenu_store}>
            <div className={classes.contianer_submenu_store_option1}>
              <div style={{ width: 40 }} />
              <div style={{ width: "100%", textAlign: "end" }}>
                <SearchText text={"Premium"} setSearch={setSearch1} search={search1} value={false} />
              </div>
              <div style={{ margin: "0 20px", borderRight: "1px solid #e3e3e3" }} />
              <div style={{ width: "100%", textAlign: "start" }}>
                <SearchText text={"Entrenamiento"} setSearch={setSearch1} search={search1} value={true} />
              </div>
              <div style={{ width: 40 }} />
            </div>
          </div>
          <div style={{ height: 60 }} />
          <div>
            <Container>
              <Grid container>
                {data &&
                  data.map((i, j) => (
                    <Grid item xs={6} sm={6} md={3} key={j} style={{ marginBottom: 30 }}>
                      <CardBall data={i} width={"100%"} setData={openModal} />
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </div>
        </>
      ) : (
        <div />
      )}

      <Maps />
      <div style={{ height: 100 }} />
      <Hashtag />
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  // stateView:      state.View.stateView,
  soccer: state.Products.soccer,
  volley: state.Products.volley,
  basket: state.Products.basket,
  status: state.Products.status,
  flagPath: state.Store.flagPath,
});

export default connect(mapStateToProps, { changeFlagPath })(Store);
