// Components
import Button from "../global/Button";
// Values/Utils
import { formatMoney } from "utils";
import { useStyles } from "style";

const CardBall = ({ data, width = 350, setData }) => {
  const classes = useStyles();
  const openModal = () => {
    try {
      setData(data);
    } catch (error) {}
  };
  return (
    <>
      <div
        className={classes.card_ball}
        style={{ width: width, position: "relative", cursor: "pointer", overflow: "hidden" }}
        onClick={openModal}
      >
        <div>
          <div className="container_ball">
            <img
              alt=""
              loading="lazy"
              draggable="false"
              src={data.models[0] ? data.models[0].picture : ""}
              className="image_ball"
            />
            <p className="title_ball" style={{ fontFamily: "m_extra_bold" }}>
              {data.name}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBall;
