import {Container, Grid}  from '@material-ui/core';

// Values
import {Us_ball, Back_us_a, Back_us_b} from 'values';
// Styles
import {useStyles}  from 'style';
const Banner = () => {
    const classes = useStyles()
    return (
        <div style={{width:'100%', background:'#2153a4', paddingTop:20, paddingBottom:150}}>
            <div style={{display:'flex', alignItems:'center', position:'relative', paddingTop:10}}>
                <Container>
                    <Grid container>
                        <Grid item sm={12} md={5}>
                            <img alt='' loading='lazy' src={Back_us_a} className={classes.back_us_a} />
                            <img alt='' loading='lazy' src={Us_ball} className={classes.us_ball} />
                        </Grid>
                        <Grid item sm={12} md={7}>
                            <h2 className={classes.us_banner_title1} style={{fontFamily:'m_bold'}}>{'Nosotros'}</h2>
                            <div className={classes.container_us_info1}>
                                <p style={{fontSize:20, color:'#fff', margin:0, fontFamily:'av_book'}}>{'Somos el corazón del juego y por tal motivo nos esforzamos todos los días para lograr llegar a las manos de nuestros consumidores a través de trabajo en equipo,buenas prácticas y mejora continua.'}</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={12} md={3}> </Grid>
                        <Grid item sm={12} md={9}>
                            <div style={{position:'relative'}}>
                                <div className={classes.container_us_info2}>
                                    <h2 className={classes.us_banner_title1} style={{fontFamily:'m_bold'}}>{'Misión'}</h2>
                                    <p className={classes.us_text_mission}>{'Construimos un mundo de unión, alegría y diversión.'}</p>
                                </div>
                                <div className={classes.container_us_info3}>
                                    <p className={classes.us_text_vision} style={{fontFamily:'av_book'}}>{'Lograr en un mediano plazo el retorno dela inversión y un clima laboral sano que nos permita minimizar la rotación del personal de manera considerable.'}</p>
                                    <h2 className={classes.us_banner_title1} style={{fontFamily:'m_bold'}}>{'Visión'}</h2>
                                </div>
                                <img alt='' loading='lazy' src={Back_us_b} className={classes.back_us_b} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

export default Banner;