import ReactLoading from "react-loading";
import {useStyles}  from '../../style';

const Button = ({text='Comprar', onClick, color='#fff', background='#000', width=180, height=60, fontSize=20, loading}) => {
    const classes = useStyles()
    return (
        <>
            <button className={classes.button} onClick={onClick}
                style={{backgroundColor:background, color:color, width:width, height:height, fontSize:fontSize, position:'relative', fontFamily:'m_bold'}}>
                    {
                        loading?
                        <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}>
                            <ReactLoading type={"bars"} color={"white"} style={{fill:'#fff', height:50, width:50}} />
                        </div>
                        :
                        text
                    }
            </button>
        </>
    );
};
export default Button;