import {reduxState}  from 'values';

export const getProducts = (soccer, basket, volley, top) =>{
    return(dispatch) =>{
        dispatch({
            type: reduxState.get_products,
            soccer, basket, volley, top
        })
    }
}
