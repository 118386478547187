import { useEffect, useState } from 'react';
import { Table, TableRow, TableBody, TableCell, TableHead, Container } from '@material-ui/core';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import { toast } from 'react-toastify';
import ReactLoading from "react-loading";

// Redux
import { updateStore } from 'redux/actions/Store';
// Components
import TableInfo from './TablesInfo';
import TableDiscount from './TableDiscount';
// Values
import { Ic_remove, Ic_less, Ic_more } from 'values';
import { formatMoney } from 'utils';
import { searchCoupon } from 'utils/products';
// Styles
import { useStyles } from '../../style';

const CancelMobile = ({ deleteProductInCart, data, pos }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', borderBottom: '1px solid #e0e0e0', marginBottom: 10, paddingBottom: 10 }}>
            <img alt="" src={Ic_remove} style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => deleteProductInCart(pos)} />
        </div>
    )
}

const Info = ({ title, value, fontFamily = 'm_regular' }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #e0e0e0', paddingBottom: 10 }}>
            <div style={{ width: '40%', display: 'flex', alignItems: 'center' }} >
                <p style={{ fontFamily: fontFamily }}>{title}</p>
            </div>
            <div style={{ width: '60%', display: 'flex', justifyContent: 'flex-end' }}>
                <p style={{ fontFamily: fontFamily }}>{value}</p>
            </div>
        </div>
    )
}

const Tables = ({ store, shippingDelivery, shippingLimit, updateStore, setACoupon, setLocalTotal }) => {
    const classes = useStyles()
    const [coupon, setCoupon] = useState('')
    const [uses, setUses] = useState(false)
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState('')
    const [minimum, setMinimum] = useState(0)
    const [value, setValue] = useState(0)

    const [total, setTotal] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [discount, setDiscount] = useState(0)

    const [delivery, setDelivery] = useState(shippingDelivery)

    const deleteProduct = (position) => {
        let auxData = []
        store.forEach((i, j) => { if (position !== j) { auxData.push(i) } })
        updateStore(auxData)
    }

    const decreaseTotal = (position) => {
        store.forEach((i, j) => {
            if (j === position) { if ((i.total - 1) !== 0) { i.total -= 1 } }
        })
        updateStore([...store])
    }

    const increaseTotal = (position) => {
        store.forEach((i, j) => {
            if (j === position) { i.total += 1 }
        })
        updateStore([...store])
    }

    const calculateSubtotal = () => {
        let subtotal = 0
        store.forEach((i, j) => {
            subtotal += i.price * i.total
        })
        setSubtotal(subtotal)
    }

    const calculateTotal = () => {
        let auxSubtotal = 0
        const shipping = delivery
        store.forEach((i, j) => { auxSubtotal += i.price * i.total })
        let auxTotal = auxSubtotal
        if (auxSubtotal >= minimum) {
            if (type === 'PERCENT') {
                setDiscount((auxTotal * (value / 100)))
                auxTotal = auxTotal - (auxTotal * (value / 100))
            }
            else {
                setDiscount(value)
                auxTotal = auxTotal - value
            }
        }
        if (auxTotal < shippingLimit) {
            auxTotal += shipping
            setDelivery(() => shippingDelivery)
        } else { setDelivery(() => 0) }
        setTotal(() => auxTotal)
        setLocalTotal(() => auxTotal)
    }

    const validateCoupon = async () => {
        setLoading(true)
        const extra = `?coupon=${coupon}`
        const token = ''
        const res = await searchCoupon(token, extra)
        if (res.data.data.id) {
            if (res.data.data.available) {
                if (uses === false) {
                    if (subtotal >= res.data.data.minimum) {
                        setUses(() => true)
                        toast.success('Cupón aplicado.')
                        setType(res.data.data.type)
                        setMinimum(res.data.data.minimum)
                        setValue(res.data.data.value)
                        setACoupon(() => coupon)
                    } else {
                        toast.warn(`Cupón valido en la compra minima de $ ${formatMoney(res.data.data.minimum)}`)
                    }
                } else { toast.warn('Ya se aplico el cupón.') }
            } else { toast.warn('El cupón expiro.') }
        } else { toast.warn('Cupón invalido.') }
        setLoading(false)
    }

    useEffect(() => {
        calculateSubtotal()
        calculateTotal()
    })
    return (
        <div className={classes.container_table}>
            <Container maxWidth="xl">
                {/* --------------------------------------------------------------------------------- */}
                {/* -------------------------------------DESKTOP------------------------------------- */}
                {/* --------------------------------------------------------------------------------- */}
                <div className={classes.table_desktop}>
                    <Table>
                        <TableHead className={classes.table_head}>
                            <TableRow style={{ backgroundColor: '#c0c0c0', borderBottom: '5px solid #fff', }}>
                                <TableCell style={{ width: '20%' }}> </TableCell>
                                <TableCell align='center' style={{ width: '20%', color: '#000', borderBottom: 0, fontSize: 18 }}>{'PRODUCTO'}</TableCell>
                                <TableCell align='center' style={{ width: '20%', color: '#000', borderBottom: 0, fontSize: 18 }}>{'CANTIDAD'}</TableCell>
                                <TableCell align='center' style={{ width: '20%', color: '#000', borderBottom: 0, fontSize: 18 }}>{'PRECIO'}</TableCell>
                                <TableCell align='center' style={{ width: '20%', color: '#000', borderBottom: 0, fontSize: 18 }}>{'TOTAL'}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                store &&
                                store.map((i, j) => (
                                    <TableRow key={uniqid()} style={{ backgroundColor: '#f2f2f2', borderTop: '3px solid #fff' }}>
                                        <TableCell align='center' style={{ width: '20%' }}>
                                            <img onClick={() => deleteProduct(j)} alt='' src={Ic_remove} style={{ cursor: 'pointer' }} />
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '20%', borderBottom: 0 }}>{i.name} - {i.color} - {i.size}</TableCell>
                                        <TableCell align='center' style={{ width: '20%', borderBottom: 0 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img onClick={() => decreaseTotal(j)} alt='' src={Ic_less} style={{ cursor: 'pointer' }} />
                                                <div style={{ width: 25 }}>{i.total}</div>
                                                <img onClick={() => increaseTotal(j)} alt='' src={Ic_more} style={{ cursor: 'pointer' }} />
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' style={{ width: '20%', borderBottom: 0 }}>{`$ ${formatMoney(i.price)} MXN`}</TableCell>
                                        <TableCell align='center' style={{ width: '20%', borderBottom: 0 }}>{`$ ${formatMoney(i.price * i.total)} MXN`}</TableCell>
                                    </TableRow>
                                ))
                            }
                            <TableInfo title={'Subtotal'} value={`$ ${formatMoney(subtotal)} MXN`} weight={600} />
                            <TableDiscount value={coupon} setValue={setCoupon} onClick={validateCoupon} loading={loading} />
                            <TableInfo title={'Descuento'} value={`$ ${formatMoney(discount)} MXN`} weight={600} />
                            <TableInfo title={'Envío'} value={`$ ${formatMoney(delivery)} MXN`} weight={600} />
                            <TableInfo title={'Total'} value={`$ ${formatMoney(total)} MXN`} weight={600}
                                weight2={600} background='#2455a5' color1='#fff' color2='#fff' />
                        </TableBody>
                    </Table>
                </div>

                {/* -------------------------------------------------------------------------------- */}
                {/* -------------------------------------MOBILE------------------------------------- */}
                {/* -------------------------------------------------------------------------------- */}
                <div className={classes.table_mobile}>
                    <Table>
                        <TableBody>
                            {
                                store &&
                                store.map((i, j) => (
                                    <TableCell key={uniqid()} style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, borderRadius: 15, background: '#F8F8F8', border: 0 }}>
                                        <CancelMobile deleteProductInCart={deleteProduct} data={i} pos={j} />
                                        <Info title={'Producto'} value={`${i.name} - ${i.color} - ${i.size} `} />

                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #e0e0e0', paddingBottom: 10 }}>
                                            <div style={{ width: '40%', display: 'flex', alignItems: 'center' }} >
                                                <p style={{ fontFamily: 'm_regular' }}>{'Cantidad'}</p>
                                            </div>
                                            <div style={{ width: '60%', display: 'flex', justifyContent: 'flex-end' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img onClick={() => decreaseTotal(j)} alt='' src={Ic_less} style={{ cursor: 'pointer', width: 20 }} />
                                                    <div style={{ padding: 10 }}>{i.total}</div>
                                                    <img onClick={() => increaseTotal(j)} alt='' src={Ic_more} style={{ cursor: 'pointer', width: 20 }} />
                                                </div>
                                            </div>
                                        </div>

                                        <Info title={'Precio'} value={`$ ${formatMoney(i.price)} MXN`} />
                                        <Info title={'Total'} value={`$ ${formatMoney(i.price * i.total)} MXN`} />

                                    </TableCell>
                                ))
                            }
                            {/* Coupon/Subtotal/Envio/Descuento/Total */}
                            <TableCell style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, borderRadius: 15, background: '#F8F8F8', border: 0 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #e0e0e0', paddingBottom: 10 }}>
                                    <div style={{ width: '40%', display: 'flex', alignItems: 'center' }} >
                                        <label >{'Cúpon:'}</label>
                                    </div>
                                    <div style={{ width: '60%', display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                                        <input defaultValue={coupon} onChange={(e) => setCoupon(e.target.value)}
                                            style={{ height: 35, border: 'none', paddingLeft: 10, textTransform: 'uppercase' }} />
                                        {/* <button className={classes.button_check} onClick={validateCoupon}> */}
                                        <p onClick={validateCoupon} style={{ margin: 0, fontWeight: 600, cursor: 'pointer', paddingTop: 10, }}>
                                            {
                                                !loading ? 'APLICAR'
                                                    :
                                                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-10%, -50%)' }}>
                                                        <ReactLoading type={"bubbles"} color={"white"} style={{ fill: '#000', height: 50, width: 50 }} />
                                                    </div>
                                            }
                                        </p>
                                    </div>
                                </div>

                                <Info title={'Subtotal'} value={`$ ${formatMoney(subtotal)} MXN`} />
                                <Info title={`Descuento`} value={`$ ${formatMoney(discount)} MXN`} />
                                <Info title={'Envio'} value={`$ ${formatMoney(delivery)} MXN`} />
                                <Info title={'Total'} value={`$ ${formatMoney(total)} MXN`} fontFamily={'m_bold'} />
                            </TableCell>
                        </TableBody>
                    </Table>
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = state => ({
    store: state.Store.data,
    shippingDelivery: state.Setup.data.shipping,
    shippingLimit: state.Setup.data.shippingLimit
})

export default connect(mapStateToProps, { updateStore })(Tables)