import {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {connect}    from 'react-redux';
import {Container, Collapse, Grid} from '@material-ui/core';
// Firebase
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
// Redux
import {saveToken, getUser, updatePicture} from 'redux/actions/PanelAdmin';
// Components
import Perfil   from './Perfil';
import Shipping from './Shipping';
import Billing  from './Billing';
import Record   from './Record';
import ButtonCamera from './ButtonCamera';
import CropImage    from './CropImage';
// Values
import {Background_profile,} from '../../values';
// Styles
import {useStyles} from '../../style';
import {getData as getUserInfo, updateUserImage} from 'utils/users';
import {deployImage} from 'utils';


const MenuOption = ({text, option, value, onChange}) =>{
    return(
        <div onClick={()=>onChange(value)} 
            style={{background:option===value?'#F36C22':'#f2f2f2', width:'100%', textAlign:'center', cursor:'pointer', borderBottom:'2px solid #f7f7f7'}}>
            <p style={{color:option===value?'#fff':'#707070', paddingTop:10, paddingBottom:10, userSelect:'none', margin:0, fontFamily:'m_regular'}}>{text}</p>
        </div>
    )
}

const Panel = ({name, lastName, getUser, picture, email, saveToken, updatePicture}) => {
    const classes   = useStyles();
    const { id }    = useParams();

    const history               = useHistory();
    const [option, setOption]   = useState(id?id:'perfil')
    const [trigger, setTrigger] = useState(0)
    const [open, setOpen]       = useState(false)

    const [cropImage, setCropImage] = useState('')

    const logOut = async() =>{
        if(window.confirm("Estas seguro que deseas cerrar sesion")){
            const auth = getAuth();
            await signOut(auth)
            .then(() => {
                window.localStorage.removeItem("gaser-name")
                window.localStorage.removeItem("gaser-lastname")
                window.localStorage.removeItem("gaser-picture")
                window.localStorage.removeItem("gaser-rol")
                window.localStorage.removeItem("gaser-key")
                window.localStorage.removeItem("gaser-email")
                saveToken('', '', '', '', '', '')
                history.push('/')
            }).catch((error) => {
                console.log('Ocurrio un error la cerrar sesion');
            });
        }
    }

    const getInfoUser = async(uid) =>{
        const resUser = await getUserInfo('', `/${uid}`)
        getUser(resUser.data.data)
    }

    const authUser = () =>{
        const auth = getAuth();
        onAuthStateChanged(auth, async(user) => {
            if(user){
                await getInfoUser(user.uid)
            }
        })
    }

    const updateImage = async(image)=>{
        const auth = getAuth();
        let newImage = await deployImage(image, "User", "User")
        await onAuthStateChanged(auth, async(user) => {
            if(user){
                await updateUserImage(user.uid, newImage)
                localStorage.setItem('gaser-picture', newImage)
                updatePicture(newImage)
                // setTrigger(()=>trigger+1)
            }
        })
    }

    useEffect(()=>{
        authUser()
    },[trigger])

    return (
        <div style={{position:'sticky', top:0, minHeight:'100vh'}}>
            <div>
                {/* -------------------------------------- */}
                {/* ------------ MENU DESKTOP ------------ */}
                {/* -------------------------------------- */}
                <div className={classes.container_panel_menu_desktop}>
                    <div style={{position:'absolute', zIndex:2, top:70, left:25, textAlign:'center'}}>
                        <div style={{position:'relative'}}>
                            <div style={{width:190, height:190, borderRadius:100, background:'silver'}}>
                                <img alt='' loading='lazy' draggable='false' src={picture} style={{width:190, height:190, borderRadius:100}} />
                            </div>
                            <ButtonCamera setImage={setCropImage} />
                        </div>
                        <CropImage image={cropImage} setImage={updateImage} />
                        <div style={{paddingTop:13}}>
                            <p style={{margin:0, fontSize:24, fontWeight:600, fontFamily:'m_semi_bold'}}>{name}</p>
                            <p style={{margin:0, fontSize:18, fontWeight:600, fontFamily:'m_semi_bold'}}>{lastName}</p>
                            <p style={{margin:0, fontSize:14, fontFamily:'m_regular'}}>{email}</p>
                        </div>
                    </div>
                    <img alt='' loading='lazy' src={Background_profile} draggable='false' style={{height:170, width:'100%', position:'sticky', top:0, zIndex:1}} />
                    <div style={{width:250, background:'#f7f7f7', height:'100%', position:'absolute', top:0, zIndex:0}}>
                        <div style={{marginTop:400}}>
                            <MenuOption text={'Mi perfil'} option={option} value={'perfil'} onChange={setOption} />
                            <MenuOption text={'Datos de envío'} option={option} value={'shipping'} onChange={setOption} />
                            <MenuOption text={'Datos de facturación'} option={option} value={'billing'} onChange={setOption} />
                            <MenuOption text={'Mis compras'} option={option} value={'record'} onChange={setOption} />
                            <MenuOption text={'Cerrar sesión'} option={option} value={'close'} onChange={logOut} />
                        </div>
                    </div>
                </div>

                {/* -------------------------------------- */}
                {/* ------------ MENU MOBILE ------------- */}
                {/* -------------------------------------- */}
                <div className={classes.container_panel_menu_mobile}>
                    <div style={{position:'relative', paddingBottom:30}}>
                        <img alt='' loading='lazy' src={Background_profile} draggable='false' style={{height:150, width:'100%', position:'absolute', top:0, zIndex:0}} />
                        <div style={{display:'flex', justifyContent:'center', height:200, paddingTop:50}}>
                            <div style={{position:'relative'}}>
                                <div style={{maxWidth:190, maxHeight:190, borderRadius:100, background:'silver'}}>
                                    <img alt='' loading='lazy' draggable='false' src={picture} style={{width:'100%', height:'100%', borderRadius:100, objectFit:'scale-down',}} />
                                </div>
                                <ButtonCamera setImage={setCropImage} />
                            </div>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <Container>
                                <p style={{margin:0, fontSize:24, fontWeight:600, fontFamily:'m_semi_bold'}}>{name} {lastName}</p>
                                <p style={{margin:0, fontSize:14, fontFamily:'m_regular'}}>{email}</p>
                            </Container>
                        </div>
                    </div>
                    <div style={{marginBottom:50}}>
                        <MenuOption text={'Mi perfil'} option={option} value={'perfil'} onChange={setOption} />
                        <MenuOption text={'Datos de envío'} option={option} value={'shipping'} onChange={setOption} />
                        <MenuOption text={'Datos de facturación'} option={option} value={'billing'} onChange={setOption} />
                        <MenuOption text={'Mis compras'} option={option} value={'record'} onChange={setOption} />
                        <MenuOption text={'Cerrar sesión'} option={option} value={'close'} onChange={logOut} />
                    </div>
                </div>

                {/* -------------------------------------- */}
                {/* ---------------- INFO ---------------- */}
                {/* -------------------------------------- */}
                <div className={classes.container_panel_menu_options}>
                    <Container maxWidth='lg'>
                        {
                            option==='perfil'?
                                <Perfil trigger={trigger} setTrigger={setTrigger} />
                            :
                            option==='shipping'?
                                <Shipping trigger={trigger} setTrigger={setTrigger} />
                            :
                            option==='billing'?
                                <Billing trigger={trigger} setTrigger={setTrigger} />
                            :
                            option==='record'?
                                <Record />
                            :
                            <div />
                        }
                    </Container>
                </div>
            </div>
            <br/>
        </div>
    );
};

const mapStateToProps = state =>({
    token:      state.Panel.token,
    name:       state.Panel.name,
    lastName:   state.Panel.lastName,
    picture:    state.Panel.picture,
    rol:        state.Panel.rol,
    email:      state.Panel.email,
    data:       state.Panel.data
})


export default connect(mapStateToProps, {saveToken, getUser, updatePicture})(Panel)