import {reduxState} from 'values';

export const saveToken = (token, name, lastName, picture, rol, email) =>{
    return(dispatch) =>{
        dispatch({
            type: reduxState.login,
            name, lastName, picture, rol, token, email
        })
    }
} 

export const getUser = (data) =>{
    return(dispatch) =>{
        dispatch({
            type: reduxState.get_user,
            data
        })
    }
} 

export const getSales = (data) =>{
    return(dispatch) =>{
        dispatch({
            type: reduxState.get_my_sales,
            data
        })
    }
} 


export const updateUser = (name, lastName) =>{
    return(dispatch) =>{
        dispatch({
            type: reduxState.update_user,
            name, lastName
        })
    }
} 

export const updatePicture = (picture) =>{
    return(dispatch) =>{
        dispatch({
            type: reduxState.update_picture,
            picture
        })
    }
}

export const refreshToken = (token) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.refresh,
            token:      token
        })
    }
} 