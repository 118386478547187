import { reduxState } from "values";

const initialState = {
    data:       [],
    flagPath:   false,
}

const reducer = (state=initialState, action) =>{
    if(action.type === reduxState.store){
        return {
            ...state,
            data: action.data
        }
    }
    if(action.type === reduxState.change_flag_path){
        return {
            ...state,
            flagPath: action.flag
        }
    }
    return state
}

export default reducer;