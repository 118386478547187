import ReactLoading from "react-loading";
// Styles
import {useStyles}  from '../../style';
const ButtonPayment = ({text='PAGAR', onClick, loading, backgroundColor='#2ed37c'}) => {
    const classes = useStyles()
    return (
        <>
            <button className={classes.button_payment} onClick={onClick} style={{position:'relative', fontFamily:'m_semi_bold', backgroundColor:backgroundColor}}>
                {
                    loading? 
                        <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-10%, -50%)'}}>
                            <ReactLoading type={"bars"} color={"white"} style={{fill:'#fff', height:60, width:60}} />
                        </div>
                    :
                        text
                }
            </button>
        </>
    );
};

export default ButtonPayment;