import { useEffect, useState } from "react";
import { Container, Grid, Modal } from "@material-ui/core";
import { toast } from "react-toastify";

// Components
import Input from "./Input";
import ButtonPayment from "../global/ButtonPayment";
// Styles
import { useStyles } from "../../style";
// Utils
import { validateCards } from "utils/store";

const publicKey = "key_Vy8TLaioBWnn9ry8hDAFqVA"; // Productive
// const publicKey = 'key_IaxjBjEbtzGkZq8Xqqc796g' // Sandbox

const ToPay = ({ paymentCart, customer, cleanAux }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [nameCard, setNameCard] = useState("");
  const [numberCard, setNumberCard] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [ccv, setCcv] = useState("");

  const [open, setOpen] = useState(false);
  const [checkOutId, setCheckOutId] = useState("");

  const [payed, setPayed] = useState(false);

  const successResponseHandler = (token) => {
    paymentCart(token.id, "", false);
    // setLoading(false)
  };

  const errorResponseHandler = () => {
    setLoading(false);
    console.log("Error in generate token card");
    toast.error("Ocurrio un error verifica los datos de tu tarjeta.");
  };

  const onSubmit = () => {
    setLoading(true);
    const card = { nameCard, numberCard, month, year, ccv };
    const res = validateCards(card);
    if (res.flag) {
      let tokenParams = {
        card: {
          number: card.numberCard,
          name: card.nameCard,
          exp_year: card.year,
          exp_month: card.month,
          cvc: card.ccv,
        },
      };
      window.Conekta.token.create(tokenParams, successResponseHandler, errorResponseHandler);
    } else {
      setLoading(false);
      toast.warn(res.message);
    }
  };

  const payWithCheckOut = async () => {
    setLoading(true);
    const res = await customer();
    if (res) {
      setCheckOutId(() => res);
      setOpen(true);
    } else {
      toast.error("Ocurrio un error, por favor vuelve a intentarlo.");
    }
    setLoading(false);
  };

  const payWithCheckOutCancel = () => {
    setLoading(false);
    setOpen(false);
    cleanAux();
  };

  const standBy = () => {};

  useEffect(() => {
    const handler = (event) => {
      try {
        const data = JSON.parse(event.data);
        // console.log("Status", data.pay)
        if (data.pay === true) {
          paymentCart("", data.orderId);
        }
      } catch (error) {}
    };
    window.addEventListener("message", handler);
    // clean up
    return () => window.removeEventListener("message", handler);
  });
  return (
    <div className={classes.container_payments} style={{ paddingBottom: 30 }}>
      {/* <div style={{display:'flex', justifyContent:'center', marginBottom:20, width:'100%'}}>
                <div style={{width:500}}>
                    <ButtonPayment onClick={loading?standBy:payWithCheckOut} loading={loading} />
                </div>
            </div> */}
      {/* <div id="conektaIframeContainer" style={{height:900}}></div> */}
      {/* <button onClick={()=>setOpen(true)}>Open</button> */}

      <div style={{ display: "flex", justifyContent: "center", marginBottom: 20, width: "100%" }}>
        <div style={{ width: 500 }}>
          <ButtonPayment onClick={loading ? standBy : payWithCheckOut} loading={loading} />
        </div>
      </div>

      <Modal
        open={open}
        onClose={payWithCheckOutCancel}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Container maxWidth="md">
          <div style={{ display: "flex", justifyContent: "center", background: "#fff" }}>
            <iframe
              width="100%"
              height="700px"
              style={{ border: "none" }}
              title="test"
              srcDoc={`
                            <!DOCTYPE html>
                            <html>
                            <head>
                                <!-- Conekta checkout -->
                                <script type="text/javascript" src="https://pay.conekta.com/v1.0/js/conekta-checkout.min.js"></script>
                                
                            </head>
                            <body>
                                <div id="conektaIframeContainer" style="height:900px;"></div>
                                <script type="text/javascript">
                                    window.ConektaCheckoutComponents.Integration({
                                        targetIFrame: "#conektaIframeContainer",
                                        allowTokenization: true, 
                                        checkoutRequestId: "${checkOutId}",
                                        publicKey: "${publicKey}",
                                        options: {
                                            styles: {
                                                inputType: 'basic',
                                                buttonType: 'rounded',
                                                states: {
                                                    empty: { borderColor: '#FFAA00' },
                                                    invalid: { borderColor: '#FF00E0' },
                                                    valid: { borderColor: '#0079c1' }
                                                },
                                            },
                                            languaje: 'es',
                                            button: {
                                                colorText: '#ffffff',
                                                backgroundColor: '#301007'
                                            },
                                            iframe: {
                                                colorText: '#2153a4',
                                                backgroundColor: '#FFFFFF'
                                            }
                                        },
                                        onCreateTokenSucceeded: function(token) {
                                            console.log(token)
                                            window.top.postMessage(
                                                JSON.stringify({
                                                    pay: true,
                                                    message: "PAYED"
                                                }),
                                                '*'
                                            );
                                        },

                                        onFinalizePayment: function(event){
                                            console.log(event);
                                            window.top.postMessage(
                                                JSON.stringify({
                                                    pay: true,
                                                    message: "PAYED",
                                                    orderId: event.id
                                                }),
                                                '*'
                                            );
                                        },

                                        onCreateTokenError: function(error) {
                                            console.log(error)

                                            window.top.postMessage(
                                                JSON.stringify({
                                                    pay: false,
                                                    message: "Error"
                                                }),
                                                '*'
                                            );
                                        }
                                    })
                                </script>
                            </body>
                            </html>
                        `}
            />
          </div>
        </Container>
      </Modal>

      {/* <iframe width='100%' height='950px' style={{border:'none'}}
                title='test'
                srcDoc={`
                    <!DOCTYPE html>
                    <html>
                    <head>
                        <!-- Conekta checkout -->
                        <script type="text/javascript" src="https://pay.conekta.com/v1.0/js/conekta-checkout.min.js"></script>
                    </head>
                    <body>
                    <div id="conektaIframeContainer" style="height:900px;"></div>
                        <script type="text/javascript">
                            window.ConektaCheckoutComponents.Integration({
                                targetIFrame: "#conektaIframeContainer",
                                allowTokenization: true, 
                                checkoutRequestId: "8132d8a3-5919-4b88-a5e9-7be905f69134",
                                publicKey: "key_HGvqyd8pi4Ry8Wypjwujsow",
                                options: {
                                    styles: {
                                        inputType: 'basic',
                                        buttonType: 'rounded',
                                        states: {
                                            empty: { borderColor: '#FFAA00' },
                                            invalid: { borderColor: '#FF00E0' },
                                            valid: { borderColor: '#0079c1' }
                                        },
                                    },
                                    languaje: 'es',
                                    button: {
                                        colorText: '#ffffff',
                                        backgroundColor: '#301007'
                                    },
                                    iframe: {
                                        colorText: '#2153a4',
                                        backgroundColor: '#FFFFFF'
                                    }
                                },
                                onCreateTokenSucceeded: function(token) {
                                    console.log(token)
                                },
                                onCreateTokenError: function(error) {
                                    console.log(error)
                                    errorTest()
                                }
                            })
                        </script>
                    </body>
                    </html>
                `} /> */}

      {/* <div className={classes.container_method_payments9} style={{background:'#163f81'}}>
                <div style={{textAlign:'end', lineHeight:1, marginRight:30}}>
                    <p style={{margin:0, color:'#fff', fontWeight:700, fontSize:60}}>{'PAGO'}</p>
                </div>
            </div> */}
      {/* <div className={classes.container_method_payments10}>
                <div className={classes.content_method_form2}>
                    <Container>
                        <Input title='Titular de la tarjeta' value={nameCard} setValue={setNameCard} />
                        <Input title='Numero de tarjeta' value={numberCard} setValue={setNumberCard} />
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <div>
                                    <Input title='Mes' value={month} setValue={setMonth} placeholder={'Ej. 02'} />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div className={classes.margin_input2}>
                                    <Input title='Año' value={year} setValue={setYear} placeholder={'Ej. 2021'} />
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <div>
                                    <Input title='CCV' value={ccv} setValue={setCcv} placeholder={'Ej. 123'} />
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{display:'flex', justifyContent:'flex-end', marginTop:10, marginBottom:20}}>
                            <div style={{width:'60%'}}>
                                <ButtonPayment onClick={loading?standBy:onSubmit} loading={loading} />
                            </div>
                        </div>
                    </Container>
                </div>
            </div> */}
    </div>
  );
};

export default ToPay;
