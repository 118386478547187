import {useState, useEffect} from 'react';
import {Container, Collapse, Grid} from '@material-ui/core';
import {connect} from 'react-redux';

// Components
import Input from './Input';
// Styles
import {useStyles}  from 'style';
// Values
import {Ic_uncheck, Ic_check, cfdiList} from 'values';

const InputSelect = ({title, value, setValue, list}) =>{
    const classes = useStyles()
    return(
        <div style={{width:'100%'}}>
            <label style={{margin:0}}>{title}</label>
            <select className={classes.input_select} value={value}
                onChange={e => setValue(e.target.value)}>
                {
                    list.map((i,j)=>(
                        <option value={i} key={j}>{i}</option>
                    ))
                }
            </select>
        </div>
    )
}

const Invoice = ({data, setFlagInvoice, setInvoiceData, setInvoiceSave}) => {
    const classes = useStyles()
    const [open, setOpen]           = useState(false)
    const [saveData, setSaveData]   = useState(true)

    const [legalName, setLegalName]             = useState('')
    const [rfc, setRfc]                         = useState('')
    const [cfdi, setCfdi]                       = useState(cfdiList[0])
    const [invoiceAddress, setInvoiceAddress]   = useState('')
    const [invoiceExt, setInvoiceExt]           = useState('')
    const [invoiceInt, setInvoiceInt]           = useState('')
    const [invoiceState, setInvoiceState]       = useState('')
    const [invoiceMunicipality, setinvoiceMun]  = useState('')
    const [invoiceArea, setinvoiceArea]         = useState('')
    const [invoiceCp, setInvoiceCp]             = useState('')
    const [invoiceEmail, setInvoiceEmail]       = useState('')

    const onChange = (opt) =>{
        setFlagInvoice(opt)
        setOpen(opt)
    }

    const saveInvoiceData = (val) =>{
        setInvoiceSave(val)
        setSaveData(val)
    }

    useEffect(()=>{
        if(data){
            const obj = {
                legalName:data.legalName, rfc:data.rfc, cfdi:data.cfdi?data.cfdi:cfdiList[0], invoiceAddress:data.invoiceAddress, 
                invoiceExt:data.invoiceExt, invoiceInt:data.invoiceInt, invoiceState:data.invoiceState, 
                invoiceMunicipality:data.invoiceMunicipality, invoiceArea:data.invoiceArea, invoiceCp:data.invoicePostalCode,
                invoiceEmail:data.invoiceEmail
            }
            // console.log('Obj1: ', obj);
            setInvoiceData(obj)

            setLegalName(data.legalName)
            setRfc(data.rfc)
            setCfdi(data.cfdi)
            setInvoiceAddress(data.invoiceAddress)
            setInvoiceExt(data.invoiceExt)
            setInvoiceInt(data.invoiceInt)
            setInvoiceState(data.invoiceState)
            setinvoiceMun(data.invoiceMunicipality)
            setinvoiceArea(data.invoiceArea)
            setInvoiceCp(data.invoicePostalCode)

            setInvoiceEmail(data.invoiceEmail)
        }
    }, [data])

    useEffect(()=>{
        const obj = {
            legalName, rfc, cfdi, invoiceAddress, invoiceExt, invoiceInt, invoiceState, invoiceMunicipality,
            invoiceArea, invoiceCp, invoiceEmail
        }
        setInvoiceData(obj)
    },[legalName, rfc, cfdi, invoiceAddress, invoiceExt, invoiceInt, invoiceState, invoiceMunicipality, invoiceArea, invoiceCp, invoiceEmail])
    return (
        <div style={{paddingBottom:25}}>
            <div style={{background:'#2455a5', paddingTop:10, paddingBottom:10, display:'flex', justifyContent:'center', marginTop:25}}>
                <p style={{fontWeight:600, fontSize:20, color:'#fff', textTransform:'uppercase', margin:0,}}>
                    {'Facturación'}
                </p>
            </div>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:25}}>
                <p>{'¿Quieres factura de tu pedido?'}</p>
                <div style={{width:50}} />
                <div onClick={()=>onChange(true)} style={{display:'flex', alignItems:'center',cursor:'pointer'}}>
                    <img alt='' src={open?Ic_check:Ic_uncheck} style={{width:17}} />
                    <p style={{padding:'0 10px'}}>{'Si'}</p>
                </div>
                <div style={{width:50}} />
                <div onClick={()=>onChange(false)} style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                    <img alt='' src={open?Ic_uncheck:Ic_check} style={{width:17}} />
                    <p style={{padding:'0 10px'}}>{'No'}</p>
                </div>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={classes.container_payments}>
                    <div className={classes.container_method_payments7}>
                        <div className={classes.content_method_form2}>
                            <div style={{height:30}} />
                            <Container>
                                <Input title='Nombre/Razón social' value={legalName} setValue={setLegalName} />
                                <Input title='RFC' value={rfc} setValue={setRfc} />
                                <Input title='Correo' value={invoiceEmail} setValue={setInvoiceEmail} />
                                <InputSelect title='Uso de CFDI' value={cfdi} setValue={setCfdi} list={cfdiList} />
                                <Grid container>
                                    <Grid item xs={12} sm={8}>
                                        <Input title='Calle' value={invoiceAddress} setValue={setInvoiceAddress} />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.margin_input}>
                                            <Input title='Num Ext' value={invoiceExt} setValue={setInvoiceExt} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <div className={classes.margin_input}>
                                            <Input title='Num Int' value={invoiceInt} setValue={setInvoiceInt} />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Input title='Estado' value={invoiceState} setValue={setInvoiceState} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={classes.margin_input}>
                                            <Input title='Municipio' value={invoiceMunicipality} setValue={setinvoiceMun} />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Input title='Colonia' value={invoiceArea} setValue={setinvoiceArea} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={classes.margin_input}>
                                            <Input title='Cp' value={invoiceCp} setValue={setInvoiceCp} />
                                        </div>
                                    </Grid>
                                </Grid>
                                <div onClick={()=>saveInvoiceData(!saveData)} style={{cursor:'pointer', display:'flex', alignItems:'center', maxWidth:350, margin:'10px 0'}}>
                                    <img alt='' src={saveData?Ic_check:Ic_uncheck} draggable='false' style={{paddingRight:10, width:17}} />
                                    <p style={{fontWeight:600, margin:0, userSelect:'none'}}>{'Guardar datos para siguiente compra'}</p>
                                </div>
                            </Container>
                            <div style={{height:30}} />
                        </div>
                    </div>
                    <div className={classes.container_method_payments8} style={{justifyContent:'flex-start'}}>
                        <div style={{lineHeight:1, marginLeft:30}}>
                            <p style={{margin:0, color:'#a3a3a3', fontWeight:700, fontSize:110}}>{'3'}</p>
                            <p className={classes.title_payments} style={{margin:0}}>{'DATOS DE'}</p>
                            <p className={classes.title_payments} style={{margin:0}}>{'FACTURACIÓN'}</p>
                            <p style={{color:'#000', fontWeight:700, fontSize:20}}>{'USAR DATOS DE ENVIO'}</p>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

const mapStateToProps = state =>({
    data: state.Panel.data,
})

export default connect(mapStateToProps, {})(Invoice);