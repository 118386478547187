import {Container, Grid}  from '@material-ui/core';
// Styles
import {useStyles}  from '../../style';
const Values = () => {
    const classes = useStyles()
    return (
        <div style={{marginTop:150, marginBottom:150}}>
            <Container>
                <div style={{textAlign:'center', position:'relative'}}>
                    <h2 className={classes.us_title_values} style={{fontFamily:'m_bold'}}>{'Valores'}</h2>
                </div>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <div style={{textAlign:'center', position:'relative'}}>
                            <p className={classes.us_title2} style={{fontFamily:'marker'}}>{'Honestidad'}</p>
                            <div style={{height:20}} />
                            <p className={classes.us_info} style={{fontFamily:'av_book'}}>{'Actuamos y hablamos con la verdad.'}</p>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <div style={{textAlign:'center', position:'relative'}}>
                            <p className={classes.us_title1} style={{fontFamily:'marker'}}>{'Alegría'}</p>
                            <div style={{height:20}} />
                            <p className={classes.us_info} style={{fontFamily:'av_book'}}>{'Disfrutamos trabajar en equipo para lograr nuestras metas.'}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div style={{textAlign:'center', position:'relative'}}>
                            <p className={classes.us_title2} style={{fontFamily:'marker'}}>{'Compromiso'}</p>
                            <div style={{height:20}} />
                            <p className={classes.us_info} style={{fontFamily:'av_book'}}>{'Cumplimos con gusto nuestras responsabilidades y actuamos con calidad.'}</p>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.us_value_space} />
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <div style={{textAlign:'center', position:'relative'}}>
                            <div style={{height:20}} />
                            <p className={classes.us_title1} style={{fontFamily:'marker'}}>{'Respeto'}</p>
                            <div style={{height:25}} />
                            <p className={classes.us_info} style={{fontFamily:'av_book'}}>{'Nos tratamos con dignidad, considerando y reconociendo los derechos propios y de los demás.'}</p>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <div style={{textAlign:'center', position:'relative'}}>
                            <div style={{height:20}} />
                            <p className={classes.us_title2} style={{fontFamily:'marker'}}>{'Humildad'}</p>
                            <div style={{height:25}} />
                            <p className={classes.us_info} style={{fontFamily:'av_book'}}>{'Reconocemos las competencias de los demás y ponemos a su servicio las nuestras.'}</p>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <div style={{textAlign:'center', position:'relative'}}>
                            <p className={classes.us_title1} style={{fontFamily:'marker'}}>{'Desarrollo Humano'}</p>
                            <p className={classes.us_info} style={{fontFamily:'av_book'}}>{'Promovemos el crecimiento y la iniciativa de nuestros colaboradores.'}</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Values;