import { useState, Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Redux
import { updateStore } from "redux/actions/Store";
import { getSales } from "redux/actions/PanelAdmin";
// Components
import Tables from "./Tables";
import Footer from "../global/Footer";
import Payments from "./Payments";
import FormUser from "./FormUser";
import Invoice from "./Invoice";
import ToPay from "./ToPay";
import Login from "./Login";
import Completed from "./Completed";
import ButtonPay from "./ButtonPay";
import PayPalButton from "./PayPalButton";
// Api
import { paymentWithCard, createACustomer } from "utils/store";
import { updatePersonalData, updateShipping, updateBilling, getMySales, validateInfoShipping } from "utils/users";

const Cart = ({ store, id, token, data, products, updateStore, getSales }) => {
  const [option, setOption] = useState(1);
  const [userData, setUserData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [coupon, setCoupon] = useState("");
  const [total, setTotal] = useState(0);
  const [auxId, setAuxId] = useState("");

  const [check, setCheck] = useState(false);

  const [completed, setCompleted] = useState(false);
  const [purchase, setPurchase] = useState(true);

  const [flagInvoice, setFlagInvoice] = useState(false);
  const [invoiceSave, setInvoiceSave] = useState(true);

  const cleanStore = () => {
    updateStore([]);
  };

  const getAllSales = async () => {
    const resSales = await getMySales(token, `/${id}`);
    getSales(resSales.data.data);
  };

  const cleanAux = () => {
    setAuxId("");
  };

  const toPayWithcard = async (tokenCard = "", order_Id = "", flagEmail = false) => {
    let paymentMethod = "PAYCARD";

    if (option === 0) {
      paymentMethod = "OXXO";
    }
    if (option === 1) {
      paymentMethod = "PAYCARD";
    }
    if (option === 2) {
      paymentMethod = "PAYPAL";
    }
    if (option === 3) {
      paymentMethod = "SPEI";
    }

    const name = userData.name;
    const lastName = userData.lastName;
    const phone = userData.phone;
    const address = userData.address;
    const numExt = userData.numExt;
    const numInt = userData.numInt;
    const state = userData.state;
    const municipality = userData.municipality;
    const area = userData.area;
    const postalCode = userData.postalCode;
    const email = data.email;
    const orderId = order_Id;

    const res = validateInfoShipping(name, lastName, phone, address, numExt, state, municipality, area, postalCode);
    if (res.flag === false) {
      toast.warn(res.message);
      return false;
    }

    if (res.flag === true) {
      const legalName = flagInvoice ? invoiceData.legalName : "";
      const invoiceEmail = flagInvoice ? invoiceData.invoiceEmail : "";
      const rfc = flagInvoice ? invoiceData.rfc : "";
      const invoiceAddress = flagInvoice ? invoiceData.invoiceAddress : "";
      const invoiceInt = flagInvoice ? invoiceData.invoiceInt : "";
      const invoiceExt = flagInvoice ? invoiceData.invoiceExt : "";
      const invoiceArea = flagInvoice ? invoiceData.invoiceArea : "";
      const invoiceMunicipality = flagInvoice ? invoiceData.invoiceMunicipality : "";
      const invoiceState = flagInvoice ? invoiceData.invoiceState : "";
      const invoicePostalCode = flagInvoice ? invoiceData.invoicePostalCode : "";
      const cfdi = flagInvoice ? invoiceData.cfdi : "";

      const salesId = auxId;

      const userId = data.id;

      const res = await paymentWithCard(
        name,
        lastName,
        phone,
        address,
        numExt,
        numInt,
        state,
        municipality,
        area,
        postalCode,
        email,
        legalName,
        invoiceEmail,
        rfc,
        invoiceAddress,
        invoiceInt,
        invoiceExt,
        invoiceArea,
        invoiceMunicipality,
        invoiceState,
        invoicePostalCode,
        cfdi,
        userId,
        tokenCard,
        coupon,
        products,
        paymentMethod,
        orderId,
        flagEmail,
        salesId,
        token
      );
      if (res.id) {
        setAuxId(res.id);
      } else {
        if (res.status1 === 200 && (res.status2 === 200 || res.status2 === 415)) {
          cleanStore();
          toast.success("Compra exitosa, se te enviara un correo.");
          setCompleted(true);
          getAllSales();
          if (purchase) {
            await updatePersonalData(userId, name, lastName, phone, token);
            await updateShipping(userId, address, numExt, numInt, area, municipality, state, postalCode, token);
          }
          if (invoiceSave && flagInvoice) {
            await updateBilling(
              userId,
              legalName,
              rfc,
              invoiceAddress,
              invoiceArea,
              invoiceExt,
              invoiceInt,
              invoiceMunicipality,
              invoiceState,
              invoicePostalCode,
              invoiceEmail,
              token
            );
          }
        }
        if (res.status1 === 400 && res.status2 === 410) {
          toast.warn("El cupon expiro.");
          return false;
        }
        if (res.status1 === 400 && res.status2 === 411) {
          toast.warn("Cupón sin minimo de compra");
          return false;
        }
        if (res.status1 === 400 && res.status2 === 412) {
          toast.warn("Un producto se ha agotado");
          return false;
        }
        if (res.status1 === 400 && res.status2 === 413) {
          toast.warn("Un producto se ha terminado");
          return false;
        }
        if (res.status1 === 400 && res.status2 === 414) {
          toast.warn("Error al hacer el pago");
          return false;
        }
      }
    }
    return true;
  };

  const generateCustomer = async () => {
    const name = userData.name;
    const lastName = userData.lastName;
    const email = data.email;
    const phone = userData.phone;
    const postalCode = userData.postalCode;
    const address = userData.address;
    const state = userData.state;
    const municipality = userData.municipality;

    const costumer = await createACustomer(
      name,
      lastName,
      email,
      phone,
      postalCode,
      address,
      state,
      municipality,
      coupon,
      products
    );

    const res = await toPayWithcard("", "", true);
    if (res) {
      return costumer;
    }
  };

  return (
    <div>
      {store.length !== 0 ? (
        <Fragment>
          <Tables setACoupon={setCoupon} setLocalTotal={setTotal} />
          {completed === false ? <Payments optionPayments={setOption} total={total} /> : <Fragment />}
          {token === "" ? (
            <Login />
          ) : completed === false ? (
            <Fragment>
              <FormUser setUserData={setUserData} setPurchase={setPurchase} />
              <Invoice
                setInvoiceSave={setInvoiceSave}
                setFlagInvoice={setFlagInvoice}
                setInvoiceData={setInvoiceData}
              />

              <div style={{ display: "flex", justifyContent: "center", marginTop: -20, marginBottom: 0 }}>
                <input type="checkbox" checked={check} onClick={() => setCheck(!check)} />
                <p style={{ marginRight: 5, marginLeft: 10 }}>{"Para continuar acepta las"}</p>
                <p
                  style={{ color: "#163F81", cursor: "pointer" }}
                  onClick={() => window.open("https://gaser.com.mx/politicas-de-devoluciones", "_blank")}
                >
                  {"Politicas de devoluciones"}
                </p>
              </div>

              {check === true ? (
                option === 0 || option === 3 ? (
                  <ButtonPay paymentCart={toPayWithcard} />
                ) : option === 2 ? (
                  <PayPalButton total={total} payment={toPayWithcard} />
                ) : (
                  <ToPay paymentCart={toPayWithcard} customer={generateCustomer} cleanAux={cleanAux} />
                )
              ) : null}
            </Fragment>
          ) : (
            <Fragment />
          )}
        </Fragment>
      ) : completed ? (
        <Fragment />
      ) : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 650 }}>
          <p style={{ fontWeight: 700, fontSize: 30 }}>{"No hay productos en tu carrito"}</p>
        </div>
      )}
      {completed ? <Completed option={option} /> : <Fragment />}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  store: state.Store.data,
  token: state.Panel.token,
  data: state.Panel.data,
  products: state.Store.data,
  id: state.Panel.data.id,
});

export default connect(mapStateToProps, { updateStore, getSales })(Cart);
