import {urlApi, urlPath, post, } from '../api';
import {emailformat} from '../index';

const url = urlApi + urlPath.webContacts

export const postData = async(name, lastName, email, phone, message, token) =>{
    const obj = {
        name, lastName, email, phone, message,
    }
    return await post(obj, url, token)
}

export const validateData = (name, lastName, email) =>{
    if(!name){
        return {flag:false, message:'Parece que algo anda mal con el nombre.'}
    }
    if(!lastName){
        return {flag:false, message:'Parece que algo anda mal con el apellido.'}
    }
    if(!email.match(emailformat)){
        return {flag:false, message:'Parece que algo anda mal con el correo.'}
    }
    return {flag:true, message:''}
}