import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes, uploadString } from "firebase/storage";


export const emailformat    = /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/;
export const phoneformat    = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const numberformat   = /^-?[1-9]\d*(\.\d+)?$/;

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") =>{
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (error) {
        console.log(error)
    }
}

export const formatDate = (date) =>{
    // https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020
    // const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric', second:'numeric' }
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha.replace('de',' ').toUpperCase()
}

export const formatDate2 = (date) =>{
    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha.replace('de',' ').toUpperCase()
}

export const formatDate3 = (date) =>{
    const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha
}

const createImage = url =>
    new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
}
)
function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc)

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    // console.log('B64: ', canvas.toDataURL('image/jpeg'));
    let str = canvas.toDataURL('image/jpeg')

    // As a blob
    // const newImage = await new Promise(resolve => {
    //     canvas.toBlob(file => {
    //         resolve(URL.createObjectURL(file))
    //     }, 'image/jpeg')
    // })

    const res = dataURLtoFile(str, 'user')

    return res
}

export const dataURLtoFile = (dataurl, filename) => {
	const arr = dataurl.split(",");
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) u8arr[n] = bstr.charCodeAt(n);

	return new File([u8arr], filename, { type: mime });
};


export const deployImage = async(image, location, name) =>{
    console.log("Deploy image");
    // https://modularfirebase.web.app/common-use-cases/storage/

    let urlImage    = ""
    let name1       = ""
    
    if(image !== "" && image !== undefined){
        const format = (image.name).split(".").pop()
        name1 = name+(new Date()).toISOString() + "." + format

        const storage   = getStorage()
        const usersCollection = ref(storage, `/Panel Web/${location}/${name1}`)
        await uploadBytesResumable(usersCollection, image)
        .then(async(uploadSnapshot) =>{
            await getDownloadURL(uploadSnapshot.ref).then((url) => {
                urlImage = url
            });
        })
    }
    return urlImage
}