import { useState, useEffect } from "react";
import { makeStyles, Container, Grid } from "@material-ui/core";
import { ComposableMap, ZoomableGroup, Geographies, Geography, Marker } from "react-simple-maps";
import { connect } from "react-redux";

import { Img_gaser_habit, Ic_call, Img_promotion } from "../../values";
import geographyObject from "values/mexico.json";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: 500,
    [theme.breakpoints.only("sm")]: {
      height: "600px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "350px",
    },
  },
  container_moreinfo_content: {
    backgroundColor: "#f36c22",
    width: 400,
    margin: 20,
    paddingLeft: 10,
    marginLeft: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 10,
      margin: 0,
    },
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      width: 120,
    },
  },
  title1: {
    fontSize: 50,
    fontWeight: 700,
    margin: 20,
    marginRight: 0,
    [theme.breakpoints.down("xs")]: {
      fontSize: 35,
    },
  },
  title2: {
    fontSize: 40,
    fontWeight: 700,
    color: "#fff",
    margin: "20px 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
}));

const stylesGeography = {
  default: {
    fill: "#ECEFF1",
    stroke: "#607D8B",
    strokeWidth: 0.05,
    outline: "none",
  },
  hover: {
    fill: "#F36C22",
    stroke: "#F36C22",
    strokeWidth: 0.05,
    outline: "none",
  },
  pressed: {
    fill: "#db6b2e",
    stroke: "#db6b2e",
    strokeWidth: 0.05,
    outline: "none",
  },
};

const DealersInfo = ({ name, phone, zone, state }) => {
  return (
    <div style={{ marginBottom: 50 }}>
      <p style={{ margin: 0, paddingTop: 10, fontSize: 24, fontWeight: 600, fontFamily: "m_extra_bold" }}></p>
      <p style={{ margin: 0, paddingTop: 10, fontSize: 18, fontWeight: 600, fontFamily: "m_medium" }}>{name}</p>
      <p style={{ margin: "10px 0", color: "#F36C22", fontSize: 18, fontFamily: "m_bold" }}>{zone}</p>
      <p style={{ margin: 0, fontFamily: "m_regular" }}>
        {state && state.map((item, index) => item + `${index === state.length - 1 ? "" : ", "}`)}
      </p>
      <div style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 20 }}>
        <img alt="" src={Ic_call} style={{ width: 15 }} />
        <p style={{ margin: 0, fontSize: 18, fontFamily: "m_medium" }}>{phone}</p>
      </div>
    </div>
  );
};

const Maps = ({ dealers }) => {
  const classes = useStyles();
  const [federalEntity, setFederalEntity] = useState("");
  const [listDealers, setListDealers] = useState([]);
  const [loading, setLoading] = useState(false);

  const findDealers = () => {
    const findInfo = dealers.filter((i) => i.state.includes(federalEntity));
    setListDealers(findInfo);
  };

  const asignState = () => {
    setLoading(true);
    setFederalEntity("Jalisco");
  };

  useEffect(() => {
    if (federalEntity !== "") {
      findDealers();
    }
  }, [federalEntity]);

  useEffect(() => {
    const timer = setTimeout(() => asignState(), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container>
      <div
        style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginTop: 100 }}
      >
        <img alt="gaser logo" loading="lazy" src={Img_gaser_habit} className={classes.logo} />
        <h2 className={classes.title1} style={{ fontFamily: "marker" }}>
          SUMATE A{" "}
        </h2>

        <div className={classes.container_moreinfo_content}>
          <h2 className={classes.title2} style={{ fontFamily: "marker" }}>
            NUESTRA FAMILIA
          </h2>
        </div>
      </div>
      <p style={{ textAlign: "center", fontSize: 20, fontFamily: "av_book" }}>
        Contacta con nuestros agentes de ventas.
      </p>

      <Grid container>
        <Grid item sm={12} md={7} id="map">
          {loading && (
            <ComposableMap projectionConfig={{ scale: 900 }} className={classes.map}>
              <ZoomableGroup center={[-460, 24]} zoom={2} disablePanning>
                <Geographies geography={geographyObject}>
                  {(geographies, projection) =>
                    geographies.map(
                      (geography, i) =>
                        geography.id !== "ATA" && (
                          <Geography
                            key={i}
                            geography={geography}
                            projection={projection}
                            onClick={(geography, evt) => {
                              setFederalEntity(geography.properties.NAME_1);
                            }}
                            style={stylesGeography}
                          />
                        )
                    )
                  }
                </Geographies>
              </ZoomableGroup>
            </ComposableMap>
          )}
        </Grid>
        <Grid item sm={12} md={5}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: 20,
              height: "100%",
            }}
          >
            {/* <p style={{ margin: 0, fontFamily: "extrabold", fontSize: 24, marginBottom: -20 }}>{federalEntity}</p> */}

            {listDealers.length !== 0 ? (
              listDealers.map((item, index) => (
                <DealersInfo
                  key={`dealers-list-${index}`}
                  name={item.name}
                  phone={item.phone}
                  zone={item.zone}
                  state={item.state}
                />
              ))
            ) : (
              <p>Parece que no tenemos ningun agente en esta zona</p>
            )}
          </div>
        </Grid>
      </Grid>

      <div
        style={{
          borderTop: "2px solid #ECEFF1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 100,
          paddingTop: 40,
        }}
      >
        <img alt="" src={Img_promotion} />
        <div>
          <p style={{ margin: 0, color: "#F36C22", fontFamily: "m_semi_bold" }}>Promotor de Ligas Deportivas</p>
          <p style={{ margin: 0, paddingTop: 10, fontSize: 18, fontWeight: 600, fontFamily: "m_medium" }}>
            Jorge Omar Gómez González
          </p>
          <div style={{ display: "flex", gap: 10, marginTop: 5 }}>
            <img alt="" src={Ic_call} />
            <p style={{ margin: 0 }}>341 100 9405</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  dealers: state.Dealers.data,
});

export default connect(mapStateToProps, {})(Maps);
