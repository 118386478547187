import {useEffect, useState}   from 'react';
import {Container, Grid} from '@material-ui/core';
import {connect}    from 'react-redux';
import {toast}      from 'react-toastify';
// Components
import Input    from './Input';
import Button   from './Button';
// Values
import {toastText, cfdiList} from 'values';
// Api
import {updateBilling} from 'utils/users';
// Styles
import {useStyles}  from 'style';

const InputSelect = ({title, value, setValue, list, blockData}) =>{
    const classes = useStyles()
    return(
        <div style={{width:'100%'}}>
            <label style={{margin:0}}>{title}</label>
            <select className={classes.input_select} value={value} disabled={blockData}
                onChange={e => setValue(e.target.value)}>
                {
                    list.map((i,j)=>(
                        <option value={i} key={j}>{i}</option>
                    ))
                }
            </select>
        </div>
    )
}

const Billing = ({data, trigger, setTrigger}) => {
    const [blockData, setBlockData] = useState('disabled')
    const [loading, setLoading]     = useState(false)

    const [legalName, setLegalName]         = useState('')
    const [rfc, setRfc]                     = useState('')
    const [invoiceAddress, setAddress]      = useState('')
    const [invoiceArea, setArea]            = useState('')
    const [invoiceNumExt, setNumExt]        = useState('')
    const [invoiceNumInt, setNumInt]        = useState('')
    const [invoiceMunicipality, setMunicip] = useState('')
    const [invoiceState, setState]          = useState('')
    const [invoiceCp, setCp]                = useState('')
    const [invoiceEmail, setEmail]          = useState('')
    const [cfdi, setCfdi]                   = useState(cfdiList[0])

    const updateData = async() =>{
        setLoading(true)
        const res = await updateBilling(data.id,legalName, rfc, invoiceAddress, invoiceArea, invoiceNumExt, invoiceNumInt, invoiceMunicipality, invoiceState, invoiceCp, invoiceEmail, cfdi)
        if(res){
            setBlockData('disabled')
            setLoading(false)
            toast.success(toastText.update)
            setTrigger(()=>(trigger+1))
        } else{
            toast.warn(toastText.warning)
        }
    }

    useEffect(()=>{
        setLegalName(data.legalName)
        setRfc(data.rfc)
        setAddress(data.invoiceAddress)
        setArea(data.invoiceArea)
        setNumExt(data.invoiceExt)
        setNumInt(data.invoiceInt)
        setMunicip(data.invoiceMunicipality)
        setState(data.invoiceState)
        setCp(data.invoicePostalCode)
        setEmail(data.invoiceEmail)
        setCfdi(data.cfdi?data.cfdi:cfdiList[0])
    },[data])
    return (
        <div style={{boxShadow:'0px 0px 10px -4px rgba(0,0,0,0.35)', borderRadius:15}}>
            <Container>
                <p style={{fontWeight:600, fontSize:20, marginBottom:25, paddingTop:15, fontFamily:'m_semi_bold'}}>{'Datos de facturación'}</p>
                <div style={{padding:'0 10px'}}>
                    <Input title={'Nombre/Razón social'} value={legalName} setValue={setLegalName} blockData={blockData} placeholder={''} />
                    <Input title={'RFC'} value={rfc} setValue={setRfc} blockData={blockData} placeholder={''} />
                    <InputSelect title='Uso de CFDI' value={cfdi} setValue={setCfdi} list={cfdiList} blockData={blockData} />

                </div>
                <Grid container>
                    <Grid xs={12} md={8}>
                        <div style={{padding:'0 10px'}}>
                            <Input title={'Calle'} value={invoiceAddress} setValue={setAddress} blockData={blockData} placeholder={''} />
                        </div>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <div style={{padding:'0 10px'}}>
                            <Input title={'Num Ext'} value={invoiceNumExt} setValue={setNumExt} blockData={blockData} placeholder={''} />
                        </div>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <div style={{padding:'0 10px'}}>
                            <Input title={'Num Int'} value={invoiceNumInt} setValue={setNumInt} blockData={blockData} placeholder={''} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={12} md={6}>
                        <div style={{padding:'0 10px'}}>
                            <Input title={'Colonia'} value={invoiceArea} setValue={setArea} blockData={blockData} placeholder={''} />
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div style={{padding:'0 10px'}}>
                            <Input title={'Municipio'} value={invoiceMunicipality} setValue={setMunicip} blockData={blockData} placeholder={''} />
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div style={{padding:'0 10px'}}>
                            <Input title={'Estado'} value={invoiceState} setValue={setState} blockData={blockData} placeholder={''} />
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div style={{padding:'0 10px'}}>
                            <Input title={'Cp'} value={invoiceCp} setValue={setCp} blockData={blockData} placeholder={''} />
                        </div>
                    </Grid>
                </Grid>
                <div style={{padding:'0 10px'}}>
                    <Input title={'Correo de facturación'} value={invoiceEmail} setValue={setEmail} blockData={blockData} placeholder={''} />
                </div>
                <div style={{height:20}} />
                <Button blockData={blockData} 
                    setBlockData={()=>setBlockData('')}
                    onClick={updateData} loading={loading} />
                <div style={{height:20}} />
            </Container>
        </div>
    );
};

const mapStateToProps = state =>({
    data:       state.Panel.data
})

export default connect(mapStateToProps, {})(Billing)