import {TableRow, TableCell} from '@material-ui/core';

const TablesInfo = ({title, value, background='#f2f2f2', color1='#000', color2='#000', weight=500, weight2=500, fontFamily='arial'}) =>{
    return(
        <TableRow style={{borderTop:'3px solid #fff'}}>
            <TableCell align='center' style={{width:'20%', borderBottom:0}}/>
            <TableCell align='center' style={{width:'20%', borderBottom:0}}/>
            <TableCell align='center' style={{width:'20%', borderBottom:0}}/>
            <TableCell align='left'
                style={{width:'20%', paddingLeft:40, borderBottom:0, backgroundColor:background, fontWeight:weight, fontFamily:fontFamily, color:color1, textTransform:'uppercase', fontSize:18}} >
                    {title}
            </TableCell>
            <TableCell align='center' style={{padding: 0, borderBottom: 0, backgroundColor:background}}> 
                <p style={{fontWeight:weight2, fontFamily:fontFamily, color:color2}}>{value}</p>
            </TableCell>
        </TableRow>
    )
}

export default TablesInfo;