import {useState}   from 'react';
import {Container}  from '@material-ui/core';
import {toast}      from 'react-toastify';
// Components
import Input from "../global/Input";
// Styles
import {useStyles}  from 'style';
// Api
import {validateData, postData} from 'utils/contact';

const Form = () => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)

    const [name, setName]           = useState('')
    const [lastName, setLastName]   = useState('')
    const [email, setEmail]         = useState('')
    const [phone, setPhone]         = useState('')
    const [message, setMessage]     = useState('')

    const cleanData = () =>{
        setLoading(false)
        setEmail('')
        setName('')
        setLastName('')
        setPhone('')
        setMessage('')
    }

    const addContact = async() =>{
        setLoading(true)
        const res1 = validateData(name, lastName, email)
        if(res1.flag){
            const res2 = await postData(name, lastName, email, phone, message)
            if(res2){
                toast.success('Gracias por tu mensaje, pronto nos pondremos en contacto')
            } else{
                toast.warn('Por favor verifica que tus datos esten correctos.')
            }
            setTimeout(()=> 
                cleanData()
            , 1000)
        } else{
            setLoading(false)
            toast.warn(res1.message)
        }

    }

    const standBy = ()=>{}
    return (
        <div>
            <Container style={{paddingTop:20}}>
                <Input title={'Nombre'} placeholder={'Tu nombre'} value={name} setValue={setName} requiered={true} />
                <Input title={'Apellidos'} placeholder={'Tus apellidos'} value={lastName} setValue={setLastName} requiered={true} />
                <Input title={'Correo'} placeholder={'Tu correo'} value={email} setValue={setEmail} requiered={true} type={'email'} />
                <Input title={'Teléfono'} placeholder={'Tu teléfono'} value={phone} setValue={setPhone} />
                <Input title={'Mensaje'} placeholder={'Cuentanos sobre tu duda, o danos tu comentario.'} value={message} setValue={setMessage} type='area' />
                <br />
                <label style={{fontFamily:'m_regular'}}>{'Al hacer clic en "Enviar", acepto la '}</label>
                <label style={{fontWeight:600, fontFamily:'m_regular'}}>{'Política de privacidad '}</label>
                <label style={{fontFamily:'m_regular'}}>{'de Gaser.'}</label><br />
                <p style={{color:'#ff1771', margin:0, fontFamily:'m_regular'}}>{'Los campos marcados con un asterisco (*) son obligatorios.'}</p>
                <div style={{height:50}} />
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                    <button className={classes.button_form} style={{fontFamily:'m_bold'}}
                        onClick={loading?standBy:addContact}>
                        {loading?'Cargando...':'Enviar'}
                    </button>
                </div>
                <div style={{height:20}} />
            </Container>
        </div>
    );
};

export default Form;