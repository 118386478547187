import {connect}    from 'react-redux';
import Lottie       from 'react-lottie';
import {useHistory, generatePath} from 'react-router-dom';

// Components
import ButtonPayment    from 'components/global/ButtonPayment';
import Hashtag          from 'components/global/Hashtag';
// Values
import {Smile_emoji} from 'values';

const Completed = ({option, name, getSales}) => {
    const history           = useHistory();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Smile_emoji,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const redirectPanel = () =>{
        history.push(generatePath('/panel/:id', {id:'record'}))
    }
    
    return (
        <div>
            <div style={{display:'flex', justifyContent:'center', background:'#2ed37c'}}>
                <p style={{color:'#fff', fontSize:48, fontWeight:700, margin:'20px 0', fontFamily:'m_bold'}}>
                    {
                        option===1?'¡Compra completada!':'¡Orden lista!'
                    }
                </p>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'100px 0', flexWrap:'wrap'}}>
                <Lottie options={defaultOptions} height={300} width={300} isClickToPauseDisabled={true} style={{margin:0}} /> 
                <div>
                    <p style={{fontWeight:600, fontSize:20, marginBottom:5, fontFamily:'m_semi_bold'}}>{`Hola ${name},`}</p>
                    <p style={{maxWidth:500, fontFamily:'m_regular'}}>
                        {
                            option===1?
                            'Gracias por tu compra. Para ver detalles de tu compra puedes hacerlo desde tu perfil.'
                            :
                            'Gracias por tu orden. Te hemos enviado un correo con los detalles para completar tu compra, también puedes consultar más información desde tu perfil.'
                        }
                    </p>
                    <div style={{width:200}}>
                        <ButtonPayment text={'Mis compras'} onClick={redirectPanel} backgroundColor='#f36c22' />
                    </div>
                </div>
            </div>
            <Hashtag />
        </div>
    );
};

const mapStateToProps = state =>({
    name: state.Panel.name,
})

export default connect(mapStateToProps, {})(Completed)