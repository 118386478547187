import {Container, Grid}  from '@material-ui/core';

// Values
import {Img_partners} from 'values';
// Styles
import {useStyles}  from 'style';

const Banner = () => {
    const classes = useStyles()
    return (
        <div className={classes.container_alliances_main}>
            <Container maxWidth='xl'>
                <div className={classes.container_contact}>
                    <Grid container>
                        <Grid item xs={12} md={1}></Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <div style={{display:'flex', alignItems:'center', height:'70%'}}>
                                <div style={{width:'100%'}}>
                                    <h2 style={{fontSize:55, color:'#fff', margin:0, fontFamily:'m_bold'}}>{'Presentes en las mejores ligas y eventos'}</h2>
                                    <p style={{margin:0, color:'#fff', fontFamily:'m_regular', marginTop:23}}>{'Orgullosos de trabajar con grandes proyectos'}</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div>
                                <img alt='' src={Img_partners} style={{width:'100%', borderRadius:12}} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
};

export default Banner;