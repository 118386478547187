import { Fragment } from 'react';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';

import Footer from '../global/Footer';
import Hashtag from '../global/Hashtag';

import { Back_us_a } from 'values';
import { useStyles } from 'style';

const PrivacyPolicies = ({ data }) => {
    const classes = useStyles()

    return (
        <Fragment>
            <div style={{ width: '100%', background: '#2153a4', paddingTop: 20, paddingBottom: 150 }}>
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative', paddingTop: 10 }}>
                    <Container style={{ position: 'relative' }}>
                        <img alt='' loading='lazy' src={Back_us_a} style={{ position: 'absolute' }} />
                        <h2 className={classes.us_banner_title1} style={{ fontFamily: 'm_bold' }}>{'Politicas de privacidad'}</h2>
                    </Container>
                </div>
            </div>
            <div style={{ width: '100%', paddingTop: 20, paddingBottom: 150 }}>
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative', paddingTop: 10 }}>
                    <Container style={{ position: 'relative' }}>
                        <div dangerouslySetInnerHTML={{ __html: data.privacyPolicy }} />
                    </Container>
                </div>
            </div>
            <Hashtag />
            <Footer />
        </Fragment>
    );
};

const mapStateToProps = state => ({
    data: state.Setup.data
})

export default connect(mapStateToProps, {})(PrivacyPolicies)