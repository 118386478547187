import { reduxState } from "values";

export const updateStore = (data) =>{
    return(dispatch) =>{
        dispatch({
            type:   reduxState.store,
            data
        })
    }
}

export const changeFlagPath = (flag) =>{
    return(dispatch) =>{
        dispatch({
            type:   reduxState.change_flag_path,
            flag
        })
    }
}